import { Theme } from '@emotion/react'
import { ObjectStatus } from '../api/coreapi'

export type ChangeType = 'Intact' | 'Added' | 'Modified' | 'Deleted' | 'Moved'

export const ObjectStatusValues = {
  Intact: 1,
  Added: 2,
  Modified: 3,
  Deleted: 4,
}

const FILE_ENTRY_STATUSES: Record<ObjectStatus, ChangeType> = {
  [ObjectStatusValues.Intact]: 'Intact',
  [ObjectStatusValues.Added]: 'Added',
  [ObjectStatusValues.Modified]: 'Modified',
  [ObjectStatusValues.Deleted]: 'Deleted',
}

export const fromFileEntryStatus = (status: ObjectStatus): ChangeType | undefined => FILE_ENTRY_STATUSES[status]

export const getChangeTypeColor = (changeType: ChangeType, theme: Theme): string | undefined => {
  const mapping: Record<ChangeType, string | undefined> = {
    Added: theme.colors.status.added,
    Deleted: theme.colors.status.deleted,
    Intact: undefined,
    Modified: theme.colors.status.modified,
    Moved: theme.colors.status.moved,
  }
  return mapping[changeType]
}

export const changeToActionDescription = (changeType: ChangeType) => {
  const mapping: Record<ChangeType, string> = {
    Added: 'added',
    Deleted: 'deleted',
    Intact: 'not changed',
    Modified: 'modified',
    Moved: 'moved',
  }
  return mapping[changeType]
}

const CASCADING_STATUSES: ChangeType[] = ['Added', 'Deleted']

export const getEffectiveChangeType = (
  parentChangeType: ChangeType,
  itemChangeType: ChangeType,
  itemMoved?: boolean
): ChangeType => {
  return itemMoved === true
    ? 'Moved'
    : CASCADING_STATUSES.includes(parentChangeType)
      ? parentChangeType
      : itemChangeType
}
