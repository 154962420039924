import FullTreeIcon from '@mui/icons-material/AccountTreeRounded'
import FlatListIcon from '@mui/icons-material/FormatListBulleted'
import { SwitchButtons } from './SwitchButtons'
import styled from '@emotion/styled'
import { TREE_LEFT_MARGIN_REM_PADDED } from '../../theme'
import { TreeViewOptions } from '../tree/TreeViewOptions'
import { ChangeTreeIcon } from './ChangeTreeIcon'

type Props = {
  selected: TreeViewOptions
  setSelected: (set: TreeViewOptions) => void
}

const StyledSwitch = styled(SwitchButtons)`
  padding: ${({ theme }) => theme.padding.m}rem ${TREE_LEFT_MARGIN_REM_PADDED}rem;
  justify-content: start;
` as typeof SwitchButtons

export const ViewChangesSwitch = ({ selected, setSelected }: Props) => (
  <StyledSwitch<TreeViewOptions>
    selected={selected}
    setSelected={setSelected}
    buttons={[
      { key: 'all', title: 'All items tree', icon: <FullTreeIcon /> },
      { key: 'tree_changes', title: 'Change tree', icon: <ChangeTreeIcon /> },
      { key: 'list_changes', title: 'Change list', icon: <FlatListIcon /> },
    ]}
  />
)
