import { Repo } from '../../api/coreapi'
import { CodeRef } from '../../components/base/CodeRef'
import { DefaultService, ApiError, UserErrors } from '../../api/agentapi'
import { useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { errorToast, infoToast } from '../../utils/toast'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocalDirSelectionDialog } from './LocalDirSelectionDialog'
import { getPathSeparatorFromPath } from '../../utils/pathUtils'

interface CloneRepoDialogProps {
  repo: Repo
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
}

function getPath(path: string, repoName: string) {
  const separator = getPathSeparatorFromPath(path)
  return `${path}${separator}${repoName}`
}

export const CloneRepoDialog = ({ repo, isOpen, setOpen }: CloneRepoDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const postAnalytics = useAnalytics()
  const onClone = useCallback(
    async (path: string) => {
      const local_path = getPath(path, repo.repo_name)
      postAnalytics('CloneRepositoryClicked', { repo_id: repo.repo_id, local_path: local_path })
      setIsLoading(true)
      try {
        await DefaultService.cloneRepository({
          repoId: repo.repo_id,
          requestBody: { Path: local_path, OverridePath: true },
        })
        setOpen(false)
        infoToast(`Repo ${repo.repo_name} cloned successfully`)
      } catch (e) {
        if (e instanceof ApiError && e.status === 400) {
          const userErrors = e.body as UserErrors
          const msg = userErrors.errors?.join('\n')
          errorToast(msg)
          log.warn(msg, e)
        } else {
          errorToast()
          log.error('Unexpected agent api error on clone repo', e)
        }
      }
      setIsLoading(false)
    },
    [repo, postAnalytics, setIsLoading, setOpen]
  )
  return (
    <LocalDirSelectionDialog
      isOpen={isOpen}
      setOpen={setOpen}
      title={'Clone repository'}
      subTitle={
        <div>
          Choose a local path to clone <CodeRef>{repo.repo_name}</CodeRef> into it
        </div>
      }
      buttonLabel={'Clone'}
      treeId={`${repo.repo_id}-clone-repo`}
      onSelected={onClone}
      loading={isLoading}
    />
  )
}
