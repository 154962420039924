import styled from '@emotion/styled'
import TimelineIcon from '@mui/icons-material/Timeline'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router'
import { routeToWorkspaceBranchGraphView } from '../../Routes'
import { useAnalytics } from '../../hooks/api/useAnalytics'

type Props = {
  repoId: string
  workspaceId: string
}

const Container = styled.div`
  align-self: center;
`

const BranchGraphButton = styled(TimelineIcon)`
  margin: 0 ${({ theme }) => theme.padding.l}rem;
  cursor: pointer;
`

export const ToBranchGraph = ({ repoId, workspaceId }: Props) => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  return (
    <Tooltip title={'View branch graph'} arrow>
      <Container
        onClick={() => {
          postAnalytics('BranchGraphClicked', { repo_id: repoId })
          navigate(routeToWorkspaceBranchGraphView(repoId, workspaceId))
        }}
      >
        <BranchGraphButton />
      </Container>
    </Tooltip>
  )
}
