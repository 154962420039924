import { IntroBody, IntroTitle } from '../IntroPage'
import { CodeRef } from '../../base/CodeRef'

export const ClonePage = () => {
  return (
    <>
      <IntroTitle>Create or clone a repo to your machine</IntroTitle>
      <IntroBody>
        After installing Diversion, you either create a new repo, or clone a repo that someone shared with you to your
        machine:
      </IntroBody>
      <IntroBody>1.&ensp;Open and login to the desktop app.</IntroBody>
      <IntroBody>
        2.&ensp;If someone shared a repo with you, click <CodeRef>Clone Repo</CodeRef> on it. If you want to create a
        new repo, click <CodeRef>New Repository</CodeRef>. You can also create one from the webapp (open the repo
        selector at the top left).
      </IntroBody>
      <IntroBody>3.&ensp;Select a local path where your working copy of the repo will be stored.</IntroBody>
      <IntroBody>
        4.&ensp;Click <CodeRef>Clone</CodeRef> or <CodeRef>Create</CodeRef>.
      </IntroBody>
    </>
  )
}
