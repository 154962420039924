import { SvgIcon } from '@mui/material'
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon'

export const ChangeTreeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="path2"
        d="M 4 3 C 2.9000022 3 2 3.9000022 2 5 L 2 9 C 2 10.109998 2.9000022 11 4 11 L 7 11 C 8.1099978 11 9 10.099998 9 9 L 9 8 L 11 8 L 11 15.009766 C 11 16.659762 12.340238 18 13.990234 18 L 15 18 L 15 19 C 15 20.109998 15.900002 21 17 21 L 20 21 C 21.109998 21 22 20.099998 22 19 L 22 15 C 22 13.890002 21.099998 13 20 13 L 17 13 C 15.890002 13 15 13.900002 15 15 L 15 16 L 13.990234 16 C 13.450236 16 13 15.549764 13 15.009766 L 13 8 L 15 8 L 15 9 C 15 10.099998 15.900002 11 17 11 L 20 11 C 21.109998 11 22 10.099998 22 9 L 22 5 C 22 3.8900022 21.099998 3 20 3 L 17 3 C 15.890002 3 15 3.9000022 15 5 L 15 6 L 9.0097656 6 L 9.0097656 5 C 9.0097656 3.8900022 8.1097634 3 7.0097656 3 L 4 3 z M 18.236328 3.8378906 L 18.855469 3.8378906 C 19.268593 3.8383969 19.612775 4.182577 19.613281 4.5957031 L 19.613281 5.8691406 L 20.884766 5.8691406 C 21.300427 5.8673781 21.647355 6.2134184 21.646484 6.6289062 L 21.646484 6.6308594 L 21.646484 7.2246094 C 21.64598 7.6387379 21.300665 7.9832849 20.886719 7.9824219 L 19.613281 7.9824219 L 19.613281 9.2558594 C 19.612775 9.6689854 19.268593 10.013166 18.855469 10.013672 L 18.236328 10.013672 C 17.823204 10.013166 17.479022 9.6689854 17.478516 9.2558594 L 17.478516 7.9824219 L 16.205078 7.9824219 C 15.791954 7.9819156 15.447772 7.6377354 15.447266 7.2246094 L 15.447266 6.6308594 C 15.445511 6.2161998 15.790417 5.8696454 16.205078 5.8691406 L 17.478516 5.8691406 L 17.478516 4.5957031 C 17.479022 4.182577 17.823204 3.8383969 18.236328 3.8378906 z M 16.955078 15.917969 L 20.130859 15.917969 A 0.65006498 0.65006498 0 0 1 20.78125 16.568359 L 20.78125 17.488281 A 0.65006498 0.65006498 0 0 1 20.130859 18.138672 L 16.955078 18.138672 A 0.65006498 0.65006498 0 0 1 16.304688 17.488281 L 16.304688 16.568359 A 0.65006498 0.65006498 0 0 1 16.955078 15.917969 z "
      />
    </SvgIcon>
  )
}
