import { useAnalytics } from '../../../hooks/api/useAnalytics'
import { useUrlState } from '../../../hooks/useUrlState'
import React, { useEffect, useState } from 'react'
import { DirectoryPickerDialog } from '../../dialogs/DirectoryPickerDialog'
import { Loader } from '../../base/Loader'
import { Tooltip } from '@mui/material'
import { Styleable } from '../../../theme'
import styled from '@emotion/styled'
import AssistantIcon from '@mui/icons-material/Assistant'
import isEmpty from 'lodash/isEmpty'
import { DialogInput } from '../../dialogs/DialogStyle'
import { useUploadFiles } from './upload/useUploadFiles'
import { useFileShaWorker } from '../../../hooks/useFileShaWorker'
import { useChangedInRef } from '../../../hooks/api/useTreeData'
import { type ContentGenerateResponse, ContentGenerationService } from '../../../api/coreapi'

type Props = Styleable & {
  onCreated: (atPath: string) => void
}

const StyledCreateContentIcon = styled(AssistantIcon)`
  cursor: pointer;
  font-size: 1.8rem;
  background: linear-gradient(45deg, rgba(235, 85, 184, 1) 0%, rgba(54, 120, 184, 1) 70%);
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.s}rem;
`
const ValidationError = styled.div`
  color: red;
  font-size: 0.8rem;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const WorkspaceCreateContent = ({ className, onCreated }: Props) => {
  const postAnalytics = useAnalytics()
  const { repoId, workspaceId } = useUrlState()
  const [targetPathDialogOpen, setTargetPathDialogOpen] = useState(false)
  const [name, setName] = useState<string>()
  const [nameErr, setNameErr] = useState<string>()
  const [creating, setCreating] = useState(false)
  const fileShaWorker = useFileShaWorker()
  const [blob, setBlob] = useState<File | undefined>(undefined)
  const [targetPath, setTargetPath] = useState<string | undefined>(undefined)
  const { refresh: refreshChanges } = useChangedInRef('TreeData', repoId!, workspaceId!, undefined)

  useEffect(() => {
    const invalidCharsRegex = /[<>:"\\/|?*]/ // Regular expression to match invalid characters
    setNameErr(name && invalidCharsRegex.test(name) ? 'Name cannot contain invalid characters' : '')
  }, [name])

  const uploadContentCallback = useUploadFiles(repoId, workspaceId, blob ? [blob!] : [], fileShaWorker)
  useEffect(() => {
    if (blob && targetPath !== undefined) {
      uploadContentCallback(targetPath!).then(() => {
        refreshChanges().then(() => {
          setTargetPathDialogOpen(false)
          setCreating(false)
        })
        setBlob(undefined)
        setTargetPath(undefined)
      })
    }
  }, [targetPath, blob, uploadContentCallback, refreshChanges])
  return (
    <>
      {targetPathDialogOpen && (
        <DirectoryPickerDialog
          title="Enter prompt for new content generation"
          subTitle={
            <InputContainer>
              <DialogInput
                autoFocus
                type="text"
                placeholder="Name"
                onChange={(event) => setName(event.target.value || '')}
              />
              {nameErr && <ValidationError>{nameErr}</ValidationError>}
            </InputContainer>
          }
          treeIdSuffix="create-dir"
          buttonLabel="Generate 🪄"
          isOpen={targetPathDialogOpen}
          handleClose={() => setTargetPathDialogOpen(false)}
          disabled={isEmpty(name) || !isEmpty(nameErr)}
          loading={creating}
          onSelected={async (path) => {
            setTargetPath(path)
            setCreating(true)
            const response = (await ContentGenerationService.srcHandlersv2ContentGenerate({
              requestBody: {
                size: '1365x1024',
                style: 'any',
                prompt: name,
              },
            })) as ContentGenerateResponse
            const output = response.output!
            const blobResponse = await fetch(output)
            const blobBuffer = await blobResponse.arrayBuffer()
            const blob = new Blob([blobBuffer], { type: 'image/png' })
            setBlob(new File([blob], `generated_${Date.now()}.png`, { type: 'image/png' }))
            setCreating(true)
          }}
        />
      )}
      <div className={className}>
        {creating ? (
          <Loader size={18} />
        ) : (
          <Tooltip title="Generate content with AI" arrow>
            <StyledCreateContentIcon
              onClick={() => {
                postAnalytics('GenerateContentClicked', { repo_id: repoId, workspace_id: workspaceId })
                setTargetPathDialogOpen(true)
              }}
            />
          </Tooltip>
        )}
      </div>
    </>
  )
}
