import { FlexColumn, FlexRow } from '../../base/Flex'
import React, { JSX } from 'react'
import { TextRegular, TextSmall } from '../../base/TextStyle'
import styled from '@emotion/styled'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import { pluralize } from '../../../utils/textUtils'
import { Ellipsis } from '../../base/Ellipsis'
import { Tooltip } from '@mui/material'

const Title = styled.div`
  ${TextRegular};
  ${Ellipsis};
`
const SecondaryText = styled.div`
  ${TextSmall};
  ${Ellipsis};
`

const SyncStatusContainer = styled(FlexRow)`
  width: 100%;
  padding: 0.5rem;
`

const TextSection = styled(FlexColumn)`
  width: calc(100% - 2.5rem);
`

type SyncStatusProps = {
  icon: JSX.Element
  primaryText?: string
  secondaryText?: string
  onClick?: () => void
  className?: string
  tooltipText?: string
}

export const SyncStatus = ({ icon, primaryText, secondaryText, onClick, className, tooltipText }: SyncStatusProps) => {
  return (
    <Tooltip title={primaryText && tooltipText} arrow>
      <SyncStatusContainer
        className={className}
        gap={1}
        clickableCursor={onClick !== undefined}
        onClick={onClick}
        centered={true}
      >
        <Tooltip title={!primaryText && tooltipText} arrow>
          {icon}
        </Tooltip>
        <TextSection gap={0.3}>
          {primaryText && <Title>{primaryText}</Title>}
          {secondaryText && <SecondaryText>{secondaryText}</SecondaryText>}
        </TextSection>
      </SyncStatusContainer>
    </Tooltip>
  )
}

const BlackSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.header.background};
  color: ${({ theme }) => theme.colors.header.primaryText};
`

const StyledErrorSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.red.primary};
  color: ${({ theme }) => theme.colors.blue.contrastText};
`
export const WarningSyncStatus = styled(SyncStatus)`
  background-color: ${({ theme }) => theme.colors.warning.primary};
  color: ${({ theme }) => theme.colors.warning.contrastText};
`

export const SyncingSyncStatus = ({ numOfWaitingFiles }: { numOfWaitingFiles?: number }) => (
  <BlackSyncStatus
    icon={<CloudUploadIcon />}
    primaryText={'Syncing'}
    secondaryText={numOfWaitingFiles ? `${pluralize(numOfWaitingFiles, 'file')}...` : undefined}
  />
)

export const UpToDateSyncStatus = () => <BlackSyncStatus icon={<CloudDoneIcon />} tooltipText={'All changes synced'} />

export const ErrorSyncStatus = ({ error }: { error?: string }) => (
  <StyledErrorSyncStatus icon={<CloudOffIcon />} primaryText={'Sync error'} secondaryText={error} tooltipText={error} />
)

export const AgentDownStatus = ({ error }: { error?: string }) => (
  <StyledErrorSyncStatus
    icon={<CloudOffIcon />}
    primaryText={'Agent down'}
    secondaryText={error}
    tooltipText={'Go to the dashboard for more info. If the problem persists please contact support.'}
  />
)
