import React from 'react'
import { ActionDialog } from './ActionDialog'
import { useLocalWorkspaces } from '../../desktop/hooks/useLocalWorkspaces'

type Props = {
  repoId: string
  workspaceId: string
  unregisterDialogOpen: boolean
  setUnregisterDialogOpen: (open: boolean) => void
}
export const UnregisterDialog = ({ repoId, workspaceId, unregisterDialogOpen, setUnregisterDialogOpen }: Props) => {
  const { unregisterWorkspace } = useLocalWorkspaces()

  return (
    <ActionDialog
      title="Disconnect Workspace"
      isOpen={unregisterDialogOpen}
      onConfirmAsync={() => unregisterWorkspace(repoId, workspaceId)}
      message="This disconnects your local workspace from Diversion. Your files will stay in place, but they will no longer synchronize to your cloud workspace.
Cannot be undone - you’ll need to clone again."
      setOpen={setUnregisterDialogOpen}
      confirmButtonLabel="Disconnect"
      loadingMessage="Disconnecting the local workspace..."
      isDanger
    />
  )
}
