import { ActionDialog } from './ActionDialog'
import { FlexColumn } from '../base/Flex'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  collaboratorName: string
  confirmAction: () => void
}

export const CollaboratorDeleteDialog = ({ isOpen, setOpen, collaboratorName, confirmAction }: Props) => {
  return (
    <ActionDialog
      title="Remove Collaborator"
      isOpen={isOpen}
      onConfirmAsync={async () => confirmAction()}
      message={
        <FlexColumn gap={1}>
          <div>You are about to remove {collaboratorName} as a collaborator, do you want to proceed?</div>
        </FlexColumn>
      }
      setOpen={setOpen}
      confirmButtonLabel="Remove"
      loadingMessage="Removing collaborator..."
      isDanger
    />
  )
}
