import { IntroPage, IntroTitle } from './IntroPage'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { ClonePage } from './subpages/ClonePage'
import { DownloadPage } from './subpages/DownloadPage'
import { FinalPage } from './subpages/FinalPage'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { FlexRowStyle } from '../base/Flex'
import { pageQueryParam, routeToDashboard } from '../../Routes'
import { useNavigate } from 'react-router'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useSearchParams } from 'react-router-dom'
import isNil from 'lodash/isNil'
import { log } from '../../utils/log'
import { UserTierChoosePage } from './subpages/UserTierChoosePage'
import { DefaultService, Tier } from '../../api/coreapi'
import { Tooltip } from '@mui/material'
import { useUserInfo } from '../../hooks/api/useUserInfo'
import { useLocalStorage } from 'usehooks-ts'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const StyledIntroPage = styled(IntroPage)`
  min-height: 480px;
`

const MainTitle = styled(IntroTitle)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black.primary};
`

const ButtonsRow = styled.div`
  ${FlexRowStyle};
  gap: 1rem;

  div {
    width: 6rem;
  }
`

const SubPageWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue.light};
  padding: ${({ theme }) => theme.padding.l}rem;
  border-radius: 0.5rem;

  > *:last-child {
    margin-bottom: 1rem;
  }
`

const enum SubPagesKeys {
  downloadCli = 'downloadCli',
  chooseSubscription = 'chooseSubscription',
  cloneRepo = 'cloneRepo',
  finalPage = 'finalPage',
}

const TIERS_WITHOUT_CONFIRMATION_CHECKBOX = [Tier.PROFESSIONAL, Tier.ENTERPRISE]

export const WelcomeRoute = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [subPage, setSubPage] = useState<number>(0)
  const postAnalytics = useAnalytics()
  const [selectedTier, setSelectedTier] = useState(Tier.PROFESSIONAL)
  const [isConfirmedTierCondition, setIsConfirmedTierCondition] = useState<boolean>(false)
  const [extraTierInfo, setExtraTierInfo] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { data: userInfo } = useUserInfo()
  const [, setFirstLogin] = useLocalStorage('session.firstLogin', true)
  const SubPages: Record<string, ReactElement> = useMemo(() => {
    const pages: Record<string, ReactElement> = {
      [SubPagesKeys.downloadCli]: <DownloadPage />,
      [SubPagesKeys.chooseSubscription]: (
        <UserTierChoosePage
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          checkboxChecked={isConfirmedTierCondition}
          setCheckboxChecked={setIsConfirmedTierCondition}
          setExtraInfo={setExtraTierInfo}
          loading={isLoading}
        />
      ),
      [SubPagesKeys.cloneRepo]: <ClonePage />,
      [SubPagesKeys.finalPage]: <FinalPage />,
    }
    if (!!userInfo && userInfo?.tier !== Tier.UNKNOWN) {
      delete pages[SubPagesKeys.chooseSubscription]
    }
    return pages
  }, [isConfirmedTierCondition, selectedTier, isLoading, userInfo])
  const subPagesLength = Object.values(SubPages).length

  useEffect(() => {
    const page = searchParams.get(pageQueryParam)
    log.info('WelcomeRoute', { page })
    if (!isNil(page)) {
      const subPage = parseInt(page!)
      if (subPage >= 0 && subPage < subPagesLength) {
        setSubPage(subPage)
      }
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams, subPagesLength])

  useEffect(() => {
    postAnalytics('WelcomePageLoaded', { welcome_page_sub_page: Object.keys(SubPages)[subPage] })
  }, [SubPages, subPage, postAnalytics])

  const setSubPageWithAnalytics = useCallback(
    (delta: number) =>
      setSubPage((current) => {
        postAnalytics('WelcomePageNavigation', {
          welcome_page_sub_page: Object.keys(SubPages)[current],
          welcome_page_target_sub_page: Object.keys(SubPages)[current + delta],
        })
        return current + delta
      }),
    [SubPages, postAnalytics]
  )

  const isInSubPage = useCallback(
    (subPageKey: SubPagesKeys) => Object.keys(SubPages)[subPage] === subPageKey,
    [SubPages, subPage]
  )

  const isNextDisabled =
    isInSubPage(SubPagesKeys.chooseSubscription) &&
    !TIERS_WITHOUT_CONFIRMATION_CHECKBOX.includes(selectedTier) &&
    !isConfirmedTierCondition

  const onNextClick = useCallback(async () => {
    if (isInSubPage(SubPagesKeys.chooseSubscription)) {
      try {
        setIsLoading(true)
        postAnalytics('SetUserTierClicked', { tier: selectedTier, extra_tier_info: extraTierInfo })
        await DefaultService.srcHandlersv2AccountUpdateTier({
          requestBody: { tier: selectedTier },
        })
      } catch (e) {
        // If we fail to update the user tier, we should proceed to the next page and not block it from start working
        log.error('Failed to update user tier in welcome page', e)
      } finally {
        setIsLoading(false)
      }
    }
    setSubPageWithAnalytics(1)
  }, [isInSubPage, setSubPageWithAnalytics, postAnalytics, selectedTier, extraTierInfo])
  return (
    <Container>
      <StyledIntroPage>
        <MainTitle>Welcome to Diversion</MainTitle>
        <SubPageWrapper>{Object.values(SubPages)[subPage]}</SubPageWrapper>
        <ButtonsRow>
          {subPage !== 0 && (
            <PrimaryButton disabled={false} onClick={() => setSubPageWithAnalytics(-1)}>
              Previous
            </PrimaryButton>
          )}
          {subPage !== subPagesLength - 1 && (
            <Tooltip title={isNextDisabled ? 'Please confirm the subscription statement to proceed' : ''} arrow>
              <PrimaryButton disabled={isNextDisabled} onClick={onNextClick}>
                Next
              </PrimaryButton>
            </Tooltip>
          )}
          {subPage === subPagesLength - 1 && (
            <PrimaryButton
              disabled={false}
              onClick={() => {
                setFirstLogin(false)
                navigate(routeToDashboard())
              }}
            >
              Finish
            </PrimaryButton>
          )}
        </ButtonsRow>
      </StyledIntroPage>
    </Container>
  )
}
