import styled from '@emotion/styled'
import { ToWorkspace } from './ToWorkspace'
import { ToMergeConflicts } from './ToMergeConflicts'
import { BranchDropdown } from '../dropdown/BranchDropdown'
import { useExpandedState } from './useExpandedStates'
import { HEADER_HEIGHT_REM } from '../../theme'
import { useUrlState } from '../../hooks/useUrlState'
import React, { useContext, useMemo, useState } from 'react'
import { InMergesContext } from '../merge/MergesRoute'
import { useMerges } from '../../hooks/api/useMerges'
import isEmpty from 'lodash/isEmpty'
import { FlexFiller, FlexRow, FlexRowStyle } from '../base/Flex'
import { InDetachedCommitContext } from '../commit/DetachedCommitRoute'
import { useLocation, useNavigate } from 'react-router'
import {
  isUnderFileHistory,
  routeToApiTokens,
  routeToDashboard,
  routeToPending,
  routeToProfile,
  routeToWebhooks,
  routeToWelcome,
  routeToWorkspaceEdit,
  routeToWorkspaceSettings,
  routeToWorkspaceView,
} from '../../Routes'
import { notNil } from '../../utils/objectUtil'
import { ToWorkspaceSettings } from './ToWorkspaceSettings'
import { LoggedInUserAvatar } from './LoggedInUserAvatar'
import { SupportButton } from '../../desktop/components/SupportBundle'
import { HomeImage } from './HomeImage'
import { Tooltip } from '@mui/material'
import { RepoDropdown } from '../dropdown/RepoDropdown'
import some from 'lodash/some'
import { PrimaryButton } from '../base/PrimaryButton'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'
import { ToBranchGraph } from './ToBranchGraph'
import { SyncStatusContext, SyncStatusType } from './syncstatus/SyncStatusContext'
import { SyncStatusBar } from './syncstatus/SyncStatusBar'
import { useLocalWorkspaces } from '../../desktop/hooks/useLocalWorkspaces'
import { useRepos } from '../../hooks/api/useRepos'

type Props = {
  repoImporting?: boolean
}

const Container = styled.div`
  ${FlexRowStyle};
  background-color: ${({ theme }) => theme.colors.header.background};
  color: ${({ theme }) => theme.colors.header.primaryText};
  min-height: ${HEADER_HEIGHT_REM}rem;
  max-height: ${HEADER_HEIGHT_REM}rem;
`

const StyledToWorkspace = styled(ToWorkspace)`
  align-self: center;
`

const VerticalSeparator = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.header.border};
`

const CurrentUser = styled(LoggedInUserAvatar)`
  cursor: pointer;
`

export const HomeButton = styled.div`
  cursor: pointer;
  align-self: center;
  margin: 0 ${({ theme }) => theme.padding.l}rem;
`

export const Header = ({ repoImporting }: Props) => {
  const navigate = useNavigate()
  const { repoId, branchId, commitId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { pathname } = useLocation()
  const { workspace, isWorkspaceCached } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const { inMergesContext } = useContext(InMergesContext)
  const { inDetachedCommitContext } = useContext(InDetachedCommitContext)
  const [actionDialogOpen, setActionDialogOpen] = useState(false)
  const repolessRoutes = [
    routeToWelcome(),
    routeToPending(),
    routeToDashboard(),
    routeToApiTokens(),
    routeToWebhooks(),
    routeToProfile(),
  ]
  const inRepolessContext = repoImporting || some(repolessRoutes, (route) => pathname.startsWith(route))
  const workspaceViews =
    repoId && workspaceId ? [routeToWorkspaceEdit(repoId, workspaceId), routeToWorkspaceView(repoId, workspaceId)] : []
  const inWorkspaceView = some(workspaceViews, (route) => pathname.startsWith(route))
  const inWorkspaceContext = notNil(workspaceId) && !isWorkspaceCached
  const inSettings = !!repoId && !!workspaceId && pathname === routeToWorkspaceSettings(repoId, workspaceId)
  const inBranchlessContext =
    repoImporting || inWorkspaceContext || inDetachedCommitContext || inMergesContext || isUnderFileHistory(pathname)
  const { repoExpanded, setRepoExpanded, branchExpanded, setBranchExpanded } = useExpandedState(
    repoId,
    branchId,
    inBranchlessContext,
    inRepolessContext,
    actionDialogOpen
  )
  const { merges } = useMerges(repoId)
  const [repoDropdownWidth, setRepoDropdownWidth] = useState<number>(0)

  const showMergeConflicts = repoId && !isEmpty(merges) && !inMergesContext
  const { syncStatus } = useContext(SyncStatusContext)
  const { data: localWorkspacesRes } = useLocalWorkspaces()
  const localWorkspace = localWorkspacesRes?.data?.find((w) => w.WorkspaceID === workspaceId)
  const { repos } = useRepos()
  const selectedRepo = useMemo(() => (repoId ? repos?.find((repo) => repo.repo_id === repoId) : null), [repoId, repos])
  return (
    <Container>
      <Tooltip title="Home" arrow>
        <HomeButton onClick={() => navigate(routeToDashboard())}>
          <HomeImage />
        </HomeButton>
      </Tooltip>
      {pathname !== routeToPending() && (
        <>
          <VerticalSeparator />
          <RepoDropdown
            repoId={repoId}
            expanded={repoExpanded}
            setExpanded={setRepoExpanded}
            inRepolessContext={inRepolessContext}
            setDropdownWidth={setRepoDropdownWidth}
            setActionDialogOpen={setActionDialogOpen}
          />
        </>
      )}
      {repoId && (
        <>
          <VerticalSeparator />
          <BranchDropdown
            repoId={repoId}
            branchId={branchId}
            commitId={commitId}
            workspaceId={isWorkspaceCached ? undefined : workspaceId}
            expanded={branchExpanded}
            setExpanded={setBranchExpanded}
            offsetLeftPx={repoDropdownWidth}
            setActionDialogOpen={setActionDialogOpen}
          />
          <VerticalSeparator />
        </>
      )}
      {inWorkspaceContext && !inSettings && (
        <>
          <ToBranchGraph repoId={repoId!} workspaceId={workspaceId!} />
          <VerticalSeparator />
          <ToWorkspaceSettings
            repoId={repoId!}
            repoName={selectedRepo?.repo_name ?? ''}
            workspaceId={workspaceId!}
            isWorkspaceSyncActive={localWorkspace?.Paused === false}
          />
          <VerticalSeparator />
          {syncStatus !== SyncStatusType.None && (
            <>
              <SyncStatusBar />
            </>
          )}
        </>
      )}
      {repoId && workspaceId && !inWorkspaceView && <StyledToWorkspace repoId={repoId} workspaceId={workspaceId!} />}
      <FlexFiller />
      <FlexRow centered={true} gap={1}>
        {showMergeConflicts && (
          <>
            <VerticalSeparator />
            <ToMergeConflicts repoId={repoId} />
            <VerticalSeparator />
          </>
        )}
        {!IsDesktopApp() && <DownloadDesktopApp />}
        <CurrentUser />
        <FlexFiller />
      </FlexRow>
      <SupportButton />
    </Container>
  )
}

const DownloadDesktopApp = () => {
  const navigate = useNavigate()
  return (
    <Tooltip title="Uploads your files faster!" arrow>
      <PrimaryButton disabled={false} onClick={() => navigate(routeToWelcome(0))}>
        Download Desktop App
      </PrimaryButton>
    </Tooltip>
  )
}
