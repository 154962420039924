import Dialog from '@mui/material/Dialog'
import { StyledDialogContent } from './DialogStyle'
import { Loader } from '../base/Loader'
import React, { useContext, useEffect, useState } from 'react'
import { useApi } from '../../hooks/useApi'
import { AccessMode, CollaboratorsService } from '../../api/coreapi'
import { useMountedState } from '../../hooks/useMountedState'
import { PublishApiErrorContext } from '../../App'
import { useCollaboratorsApis } from '../../hooks/useCollaboratorsApis'
import styled from '@emotion/styled'
import { TextBold, TextRegular, TextSmall, TextTitle } from '../base/TextStyle'
import { RepoUsers } from '../../models/RepoUsers'
import { Ellipsis } from '../base/Ellipsis'
import CloseIcon from '@mui/icons-material/Clear'
import { css } from '@emotion/react'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { UserCard } from '../base/UserCard'
import { AccessModeDropdown } from '../dropdown/AccessModeDropdown'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { Separator } from '../base/Separator'
import { PrimaryButton } from '../base/PrimaryButton'
import isEmpty from 'lodash/isEmpty'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import * as EmailValidator from 'email-validator'
import { isAdminOrAbove, useRepoPermission } from '../../hooks/api/useRepoPermission'
import { CollaboratorDeleteDialog } from './RepoCollaboratorsDeleteDialog'

type Props = {
  repoId: string
  repoName: string
  isOpen: boolean
  setOpen: (open: boolean) => void
}

const Message = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const Title = styled(Message)`
  ${TextBold};
  ${Ellipsis};
`

const Row = styled(FlexRow)`
  width: 100%;
  align-items: center;
`

const StyledCloseIcon = styled(CloseIcon)`
  color: ${({ theme }) => theme.colors.black.primary};
  cursor: pointer;
`

const StyledTable = styled.table`
  ${FlexColumn};
  width: 100%;
  tr,
  th {
    ${TextRegular};
    color: ${({ theme }) => theme.colors.black.primary};
    :first-child {
      text-align: start;
    }
  }
`

const CollaboratorsSection = styled.div`
  width: 100%;
  overflow: scroll;
`

const StyledTableRow = styled.tr<{ isHeader?: boolean }>`
  ${({ theme, isHeader }) =>
    isHeader &&
    css`
      border-bottom: 1pt solid ${theme.colors.stroke};
    `}
`

const InfoText = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const ValidationError = styled(InfoText)`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.colors.red.primary};
`

const StyledStyledDialogContent = styled(StyledDialogContent)`
  justify-content: start;
  padding: ${({ theme }) => theme.padding.xl}rem;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`

const StyledAccessModeDropdown = styled(AccessModeDropdown)<{ disabled: boolean }>`
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.padding.m}rem 0;
`

const StyledAccessModeDropdownForInvite = styled(StyledAccessModeDropdown)`
  padding: ${({ theme }) => theme.padding.m}rem;
  margin: 0 ${({ theme }) => theme.padding.l}rem;
`

const InvitedModeDropdown = styled.div<{ disabled: boolean }>`
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  border-radius: 1rem;
  padding: ${({ theme }) => theme.padding.m}rem 0;
`

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 60%;
  border-radius: 1rem;
`

export const RepoCollaboratorsDialog = ({ repoId, repoName, isOpen, setOpen }: Props) => {
  const [saveLoading, setLoading] = useMountedState(false)
  const onApiError = useContext(PublishApiErrorContext)
  const {
    data: users,
    loading: usersLoading,
    refresh,
  } = useApi<RepoUsers>(['repos', repoId, 'collaborators'], () =>
    CollaboratorsService.srcHandlersCollaborationListCollaborators({ repoId })
  )
  const { data: currentUserMode, loading: repoPermissionLoading } = useRepoPermission(repoId)
  const [invitedMode, setInvitedMode] = useState<AccessMode>(AccessMode.WRITE)
  const [invitedEmail, setInvitedEmail] = useState<string>()
  const [emailValidationError, setEmailValidationError] = useState<string>()
  const handlers = useCollaboratorsApis(repoId, setLoading, onApiError, refresh)
  const loading = usersLoading || saveLoading || repoPermissionLoading
  const readonly = !isAdminOrAbove(currentUserMode)
  const postAnalytics = useAnalytics()
  const [deleteCollaboratorDialogOpen, setDeleteCollaboratorDialogOpen] = useState(false)
  const [collaboratorName, setCollaboratorName] = useState<string>('')
  const [collaboratorDeleteFunc, setCollaboratorDeleteFunc] = useState<() => void>(() => () => {})

  useEffect(() => {
    if (!isEmpty(invitedEmail)) {
      const valid = EmailValidator.validate(invitedEmail!!)
      setEmailValidationError(valid ? undefined : 'Invalid email')
    } else {
      setEmailValidationError(undefined)
    }
  }, [invitedEmail])

  return (
    <>
      <Dialog onClose={() => setOpen(false)} aria-labelledby="customized-dialog-title" open={isOpen}>
        <StyledStyledDialogContent centerText={loading} maxHeight="60vh" width="60vw">
          <Row>
            <Title>Repository collaborators on {repoName}</Title>
            <FlexFiller />
            <StyledCloseIcon onClick={() => setOpen(false)} />
          </Row>
          {loading ? (
            <>
              <Loader />
              <Message>{saveLoading ? 'Updating access modes...' : 'Loading...'}</Message>
            </>
          ) : (
            <>
              {readonly && <InfoText>You do not have the required permissions to edit these properties.</InfoText>}
              <CollaboratorsSection>
                <StyledTable>
                  <StyledTableRow isHeader>
                    <th>Name</th>
                    <th>Permission</th>
                  </StyledTableRow>
                  {users?.collaborators?.map((collaborator) => (
                    <StyledTableRow>
                      <td>
                        <UserCard {...collaborator} />
                      </td>
                      <td>
                        <StyledAccessModeDropdown
                          disabled={collaborator.access_mode === AccessMode.OWNER || readonly}
                          selectedMode={collaborator.access_mode}
                          includeDelete
                          onSelected={(mode) => {
                            if (mode !== collaborator.access_mode) {
                              if (mode === 'DELETE') {
                                setCollaboratorName(collaborator.email!)
                                setCollaboratorDeleteFunc(() => () => handlers.revokeAccess(collaborator.user_id))
                                setDeleteCollaboratorDialogOpen(true)
                              } else {
                                handlers.updateMode(collaborator.user_id, mode)
                              }
                            }
                          }}
                        />
                      </td>
                    </StyledTableRow>
                  ))}
                  {users?.invites?.map((invite) => (
                    <StyledTableRow>
                      <td>
                        <UserCard email={invite.invited_email} />
                      </td>
                      <td>
                        <OptionsDropdown
                          items={[
                            {
                              key: 'revoke',
                              title: 'Revoke invite',
                              onSelected: () => {
                                setCollaboratorName(invite.invited_email)
                                setCollaboratorDeleteFunc(() => () => handlers.revokeInvite(invite.invite_id))
                                setDeleteCollaboratorDialogOpen(true)
                              },
                            },
                            {
                              key: 'resend',
                              title: 'Resend invite',
                              onSelected: () => handlers.resendInvite(invite.invite_id),
                            },
                          ]}
                          button={<InvitedModeDropdown disabled={readonly}>Invited</InvitedModeDropdown>}
                        />
                      </td>
                    </StyledTableRow>
                  ))}
                </StyledTable>
              </CollaboratorsSection>
              {!readonly && (
                <>
                  <Separator />
                  <Row>
                    <StyledInput
                      autoFocus
                      type="email"
                      placeholder="Email"
                      onChange={(event) => setInvitedEmail(event.target.value || '')}
                    />
                    {emailValidationError && <ValidationError>{emailValidationError}</ValidationError>}
                    <StyledAccessModeDropdownForInvite
                      selectedMode={invitedMode}
                      onSelected={(mode) => setInvitedMode(mode as AccessMode)}
                      disabled={readonly}
                      includeDelete={false}
                    />
                    <FlexFiller />
                    <PrimaryButton
                      disabled={isEmpty(invitedEmail) || !isEmpty(emailValidationError)}
                      onClick={() => {
                        postAnalytics('RepoCollaboratorsDialogInviteSent', {
                          repo_id: repoId,
                          invitee_email: invitedEmail,
                          invited_mode: invitedMode,
                        })
                        handlers.sendInvite(invitedEmail!!, invitedMode)
                      }}
                    >
                      Send invite
                    </PrimaryButton>
                  </Row>
                </>
              )}
            </>
          )}
        </StyledStyledDialogContent>
      </Dialog>
      <CollaboratorDeleteDialog
        isOpen={deleteCollaboratorDialogOpen}
        setOpen={setDeleteCollaboratorDialogOpen}
        collaboratorName={collaboratorName}
        confirmAction={collaboratorDeleteFunc}
      />
    </>
  )
}
