import styled from '@emotion/styled'
import { CommitSelector } from '../selector/CommitSelector'
import { routeToBranchView, routeToCommitView, routeToWorkspaceCommitView } from '../../Routes'
import { useCallback, useState } from 'react'
import { useTagsByCommitId } from '../../hooks/api/useTagsByCommitId'
import { Tag } from '../../api/coreapi'
import { notNil } from '../../utils/objectUtil'

type Props = {
  repoId: string
  branchId?: string
  workspaceId?: string
  checkedOutCommitId?: string
  selectedCommitId?: string
}

const Container = styled.div`
  overflow: auto;
  padding-bottom: 4rem;
`

const getVirtualCheckedOutTag = (commitId: string): Tag => ({
  id: 'checked-out',
  commit_id: commitId,
  author: {
    name: '',
    email: '',
    id: '',
  },
  name: 'Checked out',
  time: 0,
  description: 'This commit is currently checked out',
})

export const CommitHistory = ({ repoId, branchId, workspaceId, checkedOutCommitId, selectedCommitId }: Props) => {
  const inWorkspaceContext = notNil(workspaceId)
  const [query, setQuery] = useState<string>()
  const { tagsByCommitId } = useTagsByCommitId(repoId)
  const getCommitTags = useCallback(
    (commitId: string): Tag[] | undefined => {
      if (commitId !== checkedOutCommitId) {
        return tagsByCommitId[commitId]
      }
      return [...(tagsByCommitId[commitId] || []), getVirtualCheckedOutTag(checkedOutCommitId)]
    },
    [checkedOutCommitId, tagsByCommitId]
  )
  return (
    <Container>
      <CommitSelector
        repoId={repoId}
        refId={branchId || checkedOutCommitId}
        selectedCommitId={selectedCommitId}
        getTags={(commitId) => getCommitTags(commitId)}
        commitLink={(commit) =>
          inWorkspaceContext
            ? routeToWorkspaceCommitView(repoId, workspaceId!, commit.commit_id)
            : branchId
              ? routeToBranchView(repoId, branchId, commit.commit_id)
              : routeToCommitView(repoId, commit.commit_id)
        }
        query={query}
        setQuery={setQuery}
      />
    </Container>
  )
}
