import React, { useCallback } from 'react'
import { useUrlState } from '../../hooks/useUrlState'
import { errorToast, infoToast } from '../../utils/toast'
import { Commit, MergeId, NewResourceId, RepositoryCommitManipulationService } from '../../api/coreapi'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { ActionDialog } from '../dialogs/ActionDialog'
import { routeToMerge, routeToWorkspaceEdit } from '../../Routes'
import { useNavigate } from 'react-router'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { truncateCommitMessage } from '../../utils/textUtils'
import { ApiError } from '../../api/coreapi'
import { log } from '../../utils/log'
import { isMergeIdResponse } from '../../utils/idUtils'
import { TriggerRefreshAgent } from '../../desktop/components/utils/refreshAgent'

const useRevertAsync = () => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  return useCallback(
    async (repoId: string, revertCommitId: string, workspaceId: string) => {
      try {
        const response = (await RepositoryCommitManipulationService.srcHandlersv2RevertRevert({
          repoId,
          baseId: workspaceId,
          revertRefId: revertCommitId,
        })) as NewResourceId | MergeId

        if (isMergeIdResponse(response)) {
          postAnalytics('CommitConflicts', {})
          infoToast('Conflicts discovered in merge', true)
          navigate(routeToMerge(repoId, response.merge_id))
        } else {
          infoToast('Commit reverted successfully into workspace', true)
          TriggerRefreshAgent(workspaceId, repoId)
          navigate(routeToWorkspaceEdit(repoId, workspaceId))
        }
      } catch (e) {
        if (e instanceof ApiError && e.status === 409) {
          errorToast('Cannot revert as workspace has uncommitted changes')
        } else {
          errorToast('Error occurred during revert')
          log.error('Unexpected error in revert', e)
        }
      }
    },
    [navigate, postAnalytics]
  )
}

interface RevertDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  commit: Commit
  repoId?: string
}

export const RevertDialog: React.FC<RevertDialogProps> = ({ isOpen, setOpen, commit, repoId }) => {
  const { workspaceId } = useUrlState()
  const { workspace, refresh: refreshWorkspace } = useWorkspace(repoId, workspaceId)
  const postAnalytics = useAnalytics()

  const revertAsync = useRevertAsync()
  return (
    <ActionDialog
      title={`Undo commit '${truncateCommitMessage(commit)}'?`}
      message={`This action will create changes on the workspace that revert the changes made in the selected commit.
      Do you want to proceed?`}
      isOpen={isOpen}
      setOpen={setOpen}
      onConfirmAsync={async () => {
        if (!commit) {
          return
        }
        postAnalytics('RevertDialogConfirmed', { commit_id: commit.commit_id })
        await revertAsync(workspace?.repo_id!, commit.commit_id, workspace?.workspace_id!)
        refreshWorkspace()
      }}
      confirmButtonLabel={'Undo changes'}
      loadingMessage={'Creating reverted changes...'}
    />
  )
}
