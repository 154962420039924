import useSWR, { BareFetcher, useSWRConfig } from 'swr'
import { log } from '../utils/log'
import isEmpty from 'lodash/isEmpty'

type ApiState<T> = {
  data?: T
  loading: boolean
  error?: Error
  refresh: () => void
}

type ApiKey = string | (string | undefined)[]

type RefreshOptions = {
  /**
   *  polling interval in milliseconds
   */
  refreshInterval?: number
  /**
   * polling when the window is invisible (if `refreshInterval` is enabled)
   */
  refreshWhenHidden?: boolean
  /**
   * polling when the browser is offline (determined by `navigator.onLine`)
   */
  refreshWhenOffline?: boolean
}
export function useAgentApi<T>(
  requestKey: ApiKey,
  fetcher: BareFetcher<T> | null,
  immutable: boolean = false,
  refreshOptions: RefreshOptions = {}
): ApiState<T> {
  const { mutate } = useSWRConfig()
  const refresh = () => mutate(requestKey, fetcher)

  const config = immutable
    ? {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        ...refreshOptions,
      }
    : !isEmpty(refreshOptions)
      ? refreshOptions
      : undefined
  const { data, error, isLoading } = useSWR<T>(
    requestKey,
    fetcher
      ? async () => {
          const response = await fetcher()
          log.info('received api response', `'${requestKey}'`, { response })
          return response
        }
      : null,
    config
  )

  return { data, error, refresh, loading: isLoading }
}
