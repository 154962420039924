import { IntroTitle } from '../IntroPage'
import { Tier } from '../../../api/coreapi'
import { Loader } from '../../base/Loader'
import { UserTierChooser } from '../../user/UserTierChooser'

type UserTierChoosePageProps = {
  selectedTier: Tier
  setSelectedTier: (value: ((prevState: Tier) => Tier) | Tier) => void
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  setExtraInfo: (value: ((prevState: string) => string) | string) => void
  loading: boolean
}

export const UserTierChoosePage = ({
  selectedTier,
  setSelectedTier,
  checkboxChecked,
  setCheckboxChecked,
  setExtraInfo,
  loading,
}: UserTierChoosePageProps) => {
  return (
    <div>
      <IntroTitle>Choose your subscription type:</IntroTitle>
      {loading ? (
        <Loader />
      ) : (
        <UserTierChooser
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          setExtraInfo={setExtraInfo}
        />
      )}
    </div>
  )
}
