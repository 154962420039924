import { Error as CoreApiError, RepositoryWorkspaceManipulationService, Workspace } from '../../../api/coreapi'
import { DefaultService } from '../../../api/agentapi'
import config from '../../../env/config'

const isWorkspace = (data: Workspace | CoreApiError): data is Workspace => {
  return (data as Workspace).journal_ordinal_id !== undefined
}

export const WorkspaceWithJournalIdOn = config.IS_DEV_ENV
export const getWorkspaceWithAgentJournalId = async (workspaceId: string, repoId: string) => {
  const wsData = await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetWorkspace({
    repoId,
    workspaceId,
  })

  if (isWorkspace(wsData)) {
    try {
      const journalId = await DefaultService.getWorkspaceJournalId({
        repoId,
        workspaceId,
      })
      wsData.journal_ordinal_id = journalId.JournalID
    } catch (e) {
      // ignore, use the journal id from the server
    }
  }

  return wsData
}
