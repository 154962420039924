import { createContext } from 'react'

/**
 * This enum state used be able to wait until we get the updated state of the tree after commit.
 */
export enum ActionValidationState {
  // We just performed the action but didn't call the validation api yet
  PostAction,
  // No need to wait before doing the next action
  ReadyForAction,
}

type ActionValidationContextProps = {
  actionValidationState: ActionValidationState
  revalidateState: (clearChecked?: boolean) => void
}

export const ActionValidationContext = createContext<ActionValidationContextProps>({
  actionValidationState: ActionValidationState.ReadyForAction,
  revalidateState: () => {},
})
