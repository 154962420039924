import styled from '@emotion/styled'
import { BoxPadding } from './PaddingStyle'
import React, { DetailedHTMLProps, HTMLAttributes } from 'react'

export const SecondaryButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${BoxPadding};
  height: 3rem;
  border-radius: 0.5rem;

  background: ${({ theme }) => theme.colors.white.primary};
  color: ${({ theme }) => theme.colors.black.primary};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.black.primary};

  :hover {
    background: ${({ theme }) => theme.colors.blue.hover};
  }
`

type ButtonProps = {
  disabled?: boolean
  backgroundColor?: string
}

const PrimaryButtonStyle = styled(SecondaryButton)<ButtonProps>`
  background: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.blue.primary};
  color: ${({ theme }) => theme.colors.blue.contrastText};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: unset;

  :hover {
    background: ${({ theme }) =>
      `linear-gradient(123.98deg, ${theme.colors.blue.dark} 0.79%, ${theme.colors.blue.primary} 97.11%)`};
  }
`

export const PrimaryButton = React.forwardRef(
  (
    props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & ButtonProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => <PrimaryButtonStyle ref={ref} {...props} onClick={(e) => !props.disabled && props.onClick?.(e)} />
)

const DangerButtonStyle = styled(SecondaryButton)<ButtonProps>`
  background: ${({ theme }) => theme.colors.red.primary};
  color: ${({ theme }) => theme.colors.white.primary};
  opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: unset;

  :hover {
    background: ${({ theme }) => theme.colors.red.dark};
  }
`

export const DangerButton = React.forwardRef(
  (
    props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & ButtonProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => <DangerButtonStyle ref={ref} {...props} onClick={(e) => !props.disabled && props.onClick?.(e)} />
)

export const SmallButton = styled(PrimaryButton)`
  padding: 0.5rem;
  font-weight: 400;
  height: unset;
`
