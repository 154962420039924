import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Tooltip } from '@mui/material'
import { FlexRow } from './Flex'

const MiddleEllipsis = styled(FlexRow)`
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-start;
`
const TextToTruncate = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
`
const TextToPreserve = styled.div`
  white-space: nowrap;
  flex-basis: content;
  flex-grow: 0;
  flex-shrink: 0;
`

type TextTruncateAtIndexProps = {
  text: string
  truncateIndex: number
}

export const TextTruncateAtIndex: React.FC<TextTruncateAtIndexProps> = ({ text, truncateIndex }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const handleTruncate = () => {
      const element = containerRef.current
      if (element) {
        const { scrollWidth, clientWidth } = element
        setIsTruncated(scrollWidth > clientWidth)
      }
    }

    handleTruncate()

    window.addEventListener('resize', handleTruncate)
    return () => {
      window.removeEventListener('resize', handleTruncate)
    }
  }, [])

  const safeIndexToTruncate = truncateIndex < 0 || truncateIndex > text.length ? text.length : truncateIndex
  return (
    <Tooltip title={isTruncated ? text : undefined} placement={'top'} arrow>
      <MiddleEllipsis>
        <TextToTruncate ref={containerRef}>{text.slice(0, safeIndexToTruncate)}</TextToTruncate>
        <TextToPreserve>{text.slice(safeIndexToTruncate)}</TextToPreserve>
      </MiddleEllipsis>
    </Tooltip>
  )
}
