import * as React from 'react'
import { ReactNode } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { Styleable } from '../../theme'

type Props = Styleable & {
  target: string
  description?: string
  children: ReactNode
  title?: string
  isDownload?: boolean
}

export const Anchor = ({ className, target, isDownload, title, description, children }: Props) => {
  const postAnalytics = useAnalytics()
  return (
    <a
      className={className}
      href={target}
      title={title}
      onClick={(e) => {
        postAnalytics('ExternalNavigation', { external_url: target, external_url_description: description || '' })
        e.stopPropagation()
        return true
      }}
      target={isDownload ? undefined : '_blank'}
      rel={isDownload ? undefined : 'noopener noreferrer'}
      download={isDownload}
    >
      {children}
    </a>
  )
}
