/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentGenerateRequest } from '../models/ContentGenerateRequest';
import type { ContentGenerateResponse } from '../models/ContentGenerateResponse';
import type { Error } from '../models/Error';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ContentGenerationService {
    /**
     * Generate content
     * @returns ContentGenerateResponse Generated content
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2ContentGenerate({
        requestBody,
    }: {
        requestBody: ContentGenerateRequest,
    }): CancelablePromise<ContentGenerateResponse | Error> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/content/generate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
