import { User, UserManagementService } from '../../api/coreapi'
import { useState } from 'react'

export const useUpdateUserInfo = (user: User) => {
  const [isLoading, setIsLoading] = useState(false)

  const updateUserInfo = async () => {
    setIsLoading(true)
    await UserManagementService.srcHandlersv2UserUpdateUserInfo({ requestBody: user })
    setIsLoading(false)
  }

  return { isUpdateUserInfoLoading: isLoading, updateUserInfo }
}
