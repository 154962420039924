import { DefaultService, ApiError, UserErrors } from '../../api/agentapi'
import React, { useCallback, useState } from 'react'
import { log } from '../../utils/log'
import { errorToast, infoToast } from '../../utils/toast'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { LocalDirSelectionDialog } from './LocalDirSelectionDialog'
import isEmpty from 'lodash/isEmpty'
import styled from '@emotion/styled'
import { FlexRow } from '../../components/base/Flex'
import { getFileName, joinPaths } from '../../utils/pathUtils'
import { Radio, Theme } from '@mui/material'
import { LocationContext } from '../../models/Analytics'

const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.colors.blue.light};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 1rem;
  :disabled {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

const RadioStyle = (theme: Theme) => ({
  '&.Mui-checked': {
    color: theme.colors.blue.primary,
  },
  '&.Mui-checked.Mui-disabled': {
    color: theme.colors.blue.hover,
  },
})

const NoWrapText = styled.div`
  color: ${({ theme }) => theme.colors.black.primary};
  white-space: nowrap;
`
const CenteredFlexRow = styled(FlexRow)`
  width: 100%;
  align-items: center;
  gap: 1rem;
`

const RadioRow = styled(FlexRow)`
  width: 100%;
`
interface InitRepoDialogProps {
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  onSuccess: () => void
  locationContext: LocationContext
}

export const InitRepoDialog = ({ isOpen, setOpen, onSuccess, locationContext }: InitRepoDialogProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [repoName, setRepoName] = useState<string>('')
  const [createEmptyRepo, setCreateEmptyRepo] = useState<boolean>(true)
  const postAnalytics = useAnalytics()

  const onClose = useCallback(() => {
    setIsLoading(false)
    setRepoName('')
    setCreateEmptyRepo(true)
  }, [])
  const initRepoCallback = useCallback(
    async (path: string) => {
      postAnalytics('CreateNewRepositoryLocallyClicked', {}, locationContext)
      setIsLoading(true)
      try {
        const calculatedPath = createEmptyRepo ? joinPaths(path, repoName) : path
        const calculatedName = createEmptyRepo ? repoName : getFileName(calculatedPath)

        await DefaultService.repoInit({
          requestBody: { Name: calculatedName, Path: calculatedPath },
        })
        setOpen(false)
        infoToast(`Repo ${calculatedName} created successfully`)
        onSuccess()
        setRepoName('')
        setCreateEmptyRepo(true)
      } catch (e: any) {
        if (e instanceof ApiError && e.status === 400) {
          const userErrors = e.body as UserErrors
          const msg = userErrors.errors?.join('\n')
          errorToast(msg)
          log.warn(msg, e)
        } else {
          errorToast()
          log.error('Unexpected agent api error on init repo', e)
        }
      }
      setIsLoading(false)
    },
    [repoName, createEmptyRepo, postAnalytics, setIsLoading, setOpen, onSuccess, locationContext]
  )
  const updateRepoCreateOption = useCallback((isEmptyRepo: boolean) => {
    setCreateEmptyRepo(isEmptyRepo)
    setRepoName('')
  }, [])
  const subTitle = (
    <>
      <RadioRow onClick={() => updateRepoCreateOption(true)}>
        <Radio checked={createEmptyRepo} sx={RadioStyle} />
        <CenteredFlexRow>
          <NoWrapText> Create New Folder </NoWrapText>
          <StyledInput
            autoFocus
            type="text"
            placeholder={'Repository name'}
            value={repoName}
            onChange={(event) => setRepoName(event.target.value || '')}
            disabled={!createEmptyRepo}
          />
        </CenteredFlexRow>
      </RadioRow>
      <RadioRow onClick={() => updateRepoCreateOption(false)}>
        <Radio checked={!createEmptyRepo} sx={RadioStyle} />
        <CenteredFlexRow>
          <NoWrapText>Create from Existing Folder</NoWrapText>
        </CenteredFlexRow>
      </RadioRow>
    </>
  )
  return (
    <LocalDirSelectionDialog
      isOpen={isOpen}
      setOpen={setOpen}
      title={'Create New Repository'}
      subTitle={subTitle}
      buttonLabel={'Create'}
      treeId={`create-new-repo`}
      onSelected={initRepoCallback}
      loading={isLoading}
      disabledReason={createEmptyRepo && isEmpty(repoName) ? 'Repository name is required.' : undefined}
      onClose={onClose}
    />
  )
}
