import { FileEntry } from '../api/coreapi'
import { JournalOrdinalId, Tree, TreeNode } from '../models/Tree'
import { ChangeType, getEffectiveChangeType } from '../models/ChangeType'
import { getFileName, getParentPath, sanitizePath } from './pathUtils'
import isNil from 'lodash/isNil'
import { ChangedItem } from './comparisonItemUtils'
import { notNil } from './objectUtil'
import { log } from './log'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import { isDirectory } from '../models/fileMode'

type TreeItem = Pick<FileEntry, 'path'> & {
  key: string
  changeType?: ChangeType
  prevPath?: string
  isDirectory: boolean
}

const toTreeNode = (
  item: TreeItem,
  path: string,
  changeType: ChangeType,
  prevPath: string | undefined,
  staticView: boolean
): TreeNode => ({
  key: item.key,
  title: getFileName(path),
  path,
  isDirectory: item.isDirectory,
  changeType: changeType || 'Intact',
  children: item.isDirectory ? [] : undefined,
  isLeaf: !item.isDirectory,
  disableCheckbox: staticView ? false : changeType === 'Intact',
  prevPath: prevPath || item.prevPath,
})

const ROOT_PATH = ''
const getRootItem = (): TreeNode =>
  toTreeNode(
    {
      key: ROOT_PATH,
      path: ROOT_PATH,
      isDirectory: true,
    },
    ROOT_PATH,
    'Modified',
    undefined,
    false
  )

const sortFileEntries = (item1: TreeItem, item2: TreeItem) => {
  const item1isDirectory = item1.isDirectory
  const item2isDirectory = item2.isDirectory
  if (item1isDirectory && !item2isDirectory) {
    return -1 // 1 is before
  }
  if (!item1isDirectory && item2isDirectory) {
    return 1 // 2 is before
  }
  return item1.path.localeCompare(item2.path)
}

const addItemToTree = (
  item: TreeItem,
  pathToNode: Record<string, TreeNode>,
  changesByPath: Record<string, ChangedItem> | undefined,
  options: BuildTreeOptions
) => {
  const path = sanitizePath(item.path)
  const parentPath = getParentPath(path)
  const parentNode = pathToNode[parentPath]

  if (!parentNode) {
    // If we had an ordinal error, we might have a missing parent
    // since a file / folder might be changed between page fetches
    // therefore we should not log an error in this case and just return
    if (!options.hasOrdinalError) {
      log.error('no parent when adding item to tree', { path, keys: Object.keys(pathToNode) })
    }
    return
  }

  if (isNil(parentNode.children)) {
    log.error('parent node is not properly set as a directory', {
      path,
      parent: omit(parentNode, 'children'),
    })
    parentNode.children = []
  }

  const itemChangeType = isNil(changesByPath) ? item.changeType : changesByPath[path]?.changeType
  const prevPath = isNil(changesByPath) ? item.prevPath : changesByPath[path]?.previousPath
  let effectiveChangeType = getEffectiveChangeType(
    parentNode.changeType,
    itemChangeType || 'Intact',
    !isEmpty(prevPath) && prevPath !== path
  )
  if (options.staticView) {
    if (effectiveChangeType === 'Deleted') {
      return
    }
    effectiveChangeType = 'Intact'
  }
  const node: TreeNode = toTreeNode(item, path, effectiveChangeType, prevPath, Boolean(options.staticView))
  if (notNil(pathToNode[path])) {
    return
  }
  pathToNode[path] = node
  parentNode.children.push(node)
}

const toMinimalFileRecords = (files: FileEntry[]): TreeItem[] =>
  files.map((file) => ({
    ...file,
    key: sanitizePath(file.path),
    changeType: undefined,
    prevPath: undefined,
    isDirectory: isDirectory(file.mode),
  }))

const mergeSingleChildren = (current: TreeNode, nodeByKey: Record<string, TreeNode>) => {
  const { children, path, title } = current
  if (children && children.length === 1) {
    const singleChild = children[0]!!
    if (singleChild.isDirectory && current.changeType === singleChild.changeType && !singleChild.prevPath) {
      log.info('Merging child to its parent', current, singleChild)
      current.children = singleChild.children
      current.originalKey = current.key
      current.key = singleChild.key
      current.path = singleChild.path
      current.title = `${title}/${singleChild.title}`
      delete nodeByKey[path]
      nodeByKey[current.path] = current
    }
  }
  current.children?.forEach((child) => mergeSingleChildren(child, nodeByKey))
}

export type BuildTreeOptions = {
  staticView?: boolean
  dirsOnly?: boolean
  useSelectiveSync?: boolean
  skipMergeSingleChildren?: boolean
  hasOrdinalError?: boolean
}

export const buildTree = (
  files: FileEntry[],
  workspaceJournalOrdinalId: JournalOrdinalId,
  changesByPath: Record<string, ChangedItem> | undefined,
  loadedDirPaths: Set<string>,
  options: BuildTreeOptions
): Tree => {
  const root = getRootItem()
  const nodeByKey: Record<string, TreeNode> = {
    [ROOT_PATH]: root,
  }

  toMinimalFileRecords(files)
    .sort(sortFileEntries)
    .forEach((item) => addItemToTree(item, nodeByKey, changesByPath, options))

  // if changes are provided outside FileEntry status, it means items that "base" ref isn't aware of, won't be listed
  // -- that is items that are "missing" compared to "other" ref
  const missingItems: TreeItem[] = isNil(changesByPath)
    ? []
    : Object.keys(changesByPath)
        .filter((path) => changesByPath[path]!.changeType === 'Deleted')
        .map((path) => ({
          key: path,
          path,
          isDirectory: changesByPath[path]!.isDirectory,
          changeType: changesByPath[path]!.changeType,
        }))

  // Add missing items that are not in the tree yet but their parent is in the tree
  missingItems.sort(sortFileEntries).forEach((item) => {
    if (parentIncludedButChildNot(item.path, nodeByKey)) {
      addItemToTree(item, nodeByKey, changesByPath, options)
    }
  })

  const caseInsensitivePathsDuplication = getCaseInsensitiveDuplicatePaths(nodeByKey)

  if (!options.skipMergeSingleChildren) {
    mergeSingleChildren(root, nodeByKey)
  }

  return { root, workspaceJournalOrdinalId, nodeByKey, files, loadedDirPaths, caseInsensitivePathsDuplication }
}

const parentIncludedButChildNot = (path: string, nodeByPath: Record<string, TreeNode>) => {
  const sanitizedPath = sanitizePath(path)
  const parentPath = getParentPath(sanitizedPath)
  return !isNil(nodeByPath[parentPath]) && isNil(nodeByPath[sanitizedPath])
}

const getCaseInsensitiveDuplicatePaths = (nodeByKey: Record<string, TreeNode>): string[][] => {
  const lowerCaseMap: Record<string, string[]> = {}

  // Iterate over each path in nodeByKey
  Object.keys(nodeByKey).forEach((path) => {
    const lowerPath = path.toLowerCase()

    if (lowerCaseMap[lowerPath]) {
      lowerCaseMap[lowerPath].push(path)
    } else {
      lowerCaseMap[lowerPath] = [path]
    }
  })

  return Object.values(lowerCaseMap).filter((paths) => paths.length > 1)
}
