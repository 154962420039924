import { Repo } from '../../api/coreapi'
import { routeToRepo } from '../../Routes'
import { formatBytes, RIGHT_ARROW } from '../../utils/textUtils'
import { Ellipsis } from '../base/Ellipsis'
import { RefNameText, TextSmall, TextTitle } from '../base/TextStyle'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { formatTimestamp, timeAgo } from '../../utils/dateUtils'
import { Tooltip } from '@mui/material'
import { FlexFiller, FlexRow, FlexRowStyle } from '../base/Flex'
import { VerticalSeparator } from '../base/Separator'
import TimeIcon from '@mui/icons-material/AccessTime'
import SyncIcon from '@mui/icons-material/Sync'
import React, { useState } from 'react'
import { Popover } from 'antd'
import { changeColorOpacity } from '../../utils/colorUtils'
import TerminalIcon from '@mui/icons-material/Terminal'
import { CopyToClipboardAction, useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { CopyIcon } from '../base/CopyIcon'
import config from '../../env/config'
import trimEnd from 'lodash/trimEnd'
import FolderIcon from '@mui/icons-material/FolderOpen'
import { Styleable } from '../../theme'
import { Anchor } from '../base/Anchor'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { GitSyncLevel } from '../../models/GitSyncLevel'
import { CloneRepoDialog } from '../../desktop/components/CloneRepoDialog'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { RepoCollaboratorsDialog } from '../dialogs/RepoCollaboratorsDialog'
import { DeleteRepoDialog } from '../dialogs/DeleteRepoDialog'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { MoreVert } from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { isAdminOrAbove, useRepoPermission } from '../../hooks/api/useRepoPermission'

type Props = Styleable & {
  repo: Repo
  onDeleted: () => void
}

const Container = styled.div`
  width: calc(50% - ${({ theme }) => theme.padding.m}rem);
  background: ${({ theme }) => theme.colors.card.primary};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.xl}rem;
  border-radius: 0.7rem;
  box-sizing: border-box;
`

const RepoActionLink = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  gap: 0.2rem;
`

const Row = styled.div<{ isFirst?: boolean }>`
  ${FlexRowStyle};
  margin-top: ${({ isFirst }) => (isFirst ? 'unset' : '0.5rem')};
  width: 100%;
  gap: 1rem;
  align-items: center;
`

const RepoViewLinkArrow = styled.span`
  vertical-align: middle;
`

const EllipsisTitle = styled.div`
  ${TextTitle};
  ${Ellipsis};
  color: ${({ theme }) => theme.colors.black.primary};
  cursor: pointer;
`

const InfoText = styled.div`
  ${TextSmall};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const StyledSeparator = styled(VerticalSeparator)`
  height: 1.5rem;
`

const StyledTimeIcon = styled(TimeIcon)`
  ${TextSmall};
  margin-bottom: 2px;
`

const StyledSyncIcon = StyledTimeIcon.withComponent(SyncIcon)

const StyledFolderIcon = StyledTimeIcon.withComponent(FolderIcon)

const StyledCloneIcon = styled(TerminalIcon)`
  margin-bottom: 1px;
`

const SettingsButton = styled.div`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
  align-self: center;
`

const PopoverText = styled.div`
  cursor: default;
  color: ${({ theme }) => theme.colors.blue.contrastText};

  a {
    cursor: pointer;

    :hover {
      color: ${({ theme }) => changeColorOpacity(theme.colors.white.primary, 0.8)};
    }
  }
`

const SyncedPopover = ({ link }: { link: string }) => (
  <PopoverText>
    Synced with{' '}
    <Anchor target={link} description="Repo git sync url">
      {link}
    </Anchor>
  </PopoverText>
)

const ScriptRow = styled.div`
  width: fit-content;
  cursor: text;
  background-color: ${({ theme }) => theme.colors.white.primary};
  color: ${({ theme }) => theme.colors.black.primary};
  border-radius: 0.5rem;
  ${RefNameText};
  margin: ${({ theme }) => theme.padding.m}rem 0;
  padding: ${({ theme }) => theme.padding.s}rem ${({ theme }) => theme.padding.m}rem;
  white-space: nowrap;
  overflow-x: auto;
`

const ClonePopover = ({ copyToClipboard, repoName }: { copyToClipboard: CopyToClipboardAction; repoName: string }) => {
  const command = `${config.CLI_EXECUTABLE} clone "${repoName}"`
  return (
    <PopoverText>
      <div>
        <b>Open the desktop app to get a local copy of this repo.</b>
        <br />
        Alternatively, copy and paste to your terminal to clone using the Diversion CLI:
      </div>
      <ScriptRow>
        {command}
        <CopyIcon copyToClipboard={copyToClipboard} title="Copy command" text={command} />
      </ScriptRow>
    </PopoverText>
  )
}

const StyledMoreVert = styled(MoreVert)`
  padding: 0, 0.5rem;
`

const DropDownOption = styled(FlexRow)`
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`

const RepoActions = ({ repo, onDelete }: { repo: Repo; onDelete: () => void }) => {
  const [collaboratorsDialogOpen, setCollaboratorsDialogOpen] = useState(false)
  const [deleteRepoDialogOpen, setDeleteRepoDialogOpen] = useState(false)
  const { data: repoPermission } = useRepoPermission(repo.repo_id)
  const actions = [
    {
      key: 'collaborators',
      title: (
        <DropDownOption>
          <GroupsIcon /> Collaborators
        </DropDownOption>
      ),
      onSelected: () => setCollaboratorsDialogOpen(true),
    },
  ]
  if (isAdminOrAbove(repoPermission)) {
    actions.push({
      key: 'delete',
      title: (
        <DropDownOption>
          <DeleteForeverIcon /> Delete
        </DropDownOption>
      ),
      onSelected: () => setDeleteRepoDialogOpen(true),
    })
  }

  return (
    <>
      {collaboratorsDialogOpen && (
        <RepoCollaboratorsDialog
          repoId={repo.repo_id}
          repoName={repo.repo_name}
          isOpen={collaboratorsDialogOpen}
          setOpen={setCollaboratorsDialogOpen}
        />
      )}
      {deleteRepoDialogOpen && (
        <DeleteRepoDialog
          repoId={repo.repo_id}
          repoName={repo.repo_name}
          afterDelete={onDelete}
          isOpen={deleteRepoDialogOpen}
          setIsOpen={setDeleteRepoDialogOpen}
        />
      )}
      <OptionsDropdown
        items={actions}
        button={
          <SettingsButton>
            <StyledMoreVert />
          </SettingsButton>
        }
      />
    </>
  )
}

export const RepoCard = ({ className, repo, onDeleted }: Props) => {
  const navigate = useNavigate()
  return (
    <Container className={className}>
      <Row isFirst>
        <EllipsisTitle title={repo.repo_name} onClick={() => navigate(routeToRepo(repo.repo_id))}>
          {repo.repo_name}
        </EllipsisTitle>
        <FlexFiller />
        <RepoActions repo={repo} onDelete={onDeleted} />
      </Row>
      <Row>
        <Tooltip title={formatTimestamp(repo.created_timestamp, true)} arrow>
          <InfoText>
            <StyledTimeIcon /> Created {timeAgo(repo.created_timestamp)}
          </InfoText>
        </Tooltip>
        {repo.sync_git_repo_url && repo.sync_git_level === GitSyncLevel.FullHistoryWithSync && (
          <Popover content={<SyncedPopover link={trimEnd(repo.sync_git_repo_url, '.git')} />} placement="bottom">
            <InfoText>
              <StyledSyncIcon /> Synced to Git
            </InfoText>
          </Popover>
        )}
        <FlexFiller />
      </Row>
      <Row>
        <InfoText>
          <Tooltip title={'Total size of all files across all revisions and workspaces. Updated periodically.'} arrow>
            <InfoText>
              <StyledFolderIcon /> {repo.size_bytes > 0 ? formatBytes(repo.size_bytes) : 'Unknown'}
            </InfoText>
          </Tooltip>
        </InfoText>
      </Row>
      {IsDesktopApp() ? <RepoDesktopActions repo={repo} /> : <RepoWebActions repo={repo} />}
    </Container>
  )
}

interface RepoWebActionsProps {
  repo: Repo
}

const ViewRepoActionLink = ({ repo }: { repo: Repo }) => {
  const postAnalytics = useAnalytics()
  const navigate = useNavigate()
  return (
    <RepoActionLink
      onClick={() => {
        postAnalytics('RepoViewClicked', { repo_id: repo.repo_id })
        navigate(routeToRepo(repo.repo_id))
      }}
    >
      View Repo <RepoViewLinkArrow>{RIGHT_ARROW}</RepoViewLinkArrow>
    </RepoActionLink>
  )
}

const RepoWebActions = ({ repo }: RepoWebActionsProps) => {
  const copyToClipboard = useCopyToClipboardAction()
  const postAnalytics = useAnalytics()

  return (
    <Row>
      <FlexFiller />
      <Popover
        content={<ClonePopover copyToClipboard={copyToClipboard} repoName={repo.repo_name} />}
        placement="bottom"
        onOpenChange={(open) => {
          if (open) {
            postAnalytics('RepoClonePopoverOpened', { repo_id: repo.repo_id })
          }
        }}
      >
        <RepoActionLink>
          <StyledCloneIcon /> Clone Repo
        </RepoActionLink>
      </Popover>
      <StyledSeparator />
      <ViewRepoActionLink repo={repo} />
    </Row>
  )
}

interface RepoDesktopActionsProps {
  repo: Repo
}
const RepoDesktopActions = ({ repo }: RepoDesktopActionsProps) => {
  const [isCloneRepoOpen, setIsCloneRepoOpen] = useState<boolean>(false)

  return (
    <Row>
      {isCloneRepoOpen && <CloneRepoDialog isOpen={isCloneRepoOpen} setOpen={setIsCloneRepoOpen} repo={repo} />}
      <FlexFiller />
      <RepoActionLink onClick={() => setIsCloneRepoOpen(true)}>
        <StyledCloneIcon /> Clone Repo
      </RepoActionLink>
      <StyledSeparator />
      <ViewRepoActionLink repo={repo} />
    </Row>
  )
}
