import React from 'react'
import { ActionDialog } from './ActionDialog'
import { useLocalWorkspaces } from '../../desktop/hooks/useLocalWorkspaces'

type Props = {
  workspaceId: string
  isWorkspaceSyncActive: boolean
  rescanDialogOpen: boolean
  setRescanDialogOpen: (open: boolean) => void
}
export const RescanDialog = ({ workspaceId, isWorkspaceSyncActive, rescanDialogOpen, setRescanDialogOpen }: Props) => {
  const { rescanWorkspace } = useLocalWorkspaces()

  return (
    <ActionDialog
      title="Rescan Local Workspace"
      isOpen={rescanDialogOpen}
      onConfirmAsync={() => rescanWorkspace(workspaceId, isWorkspaceSyncActive)}
      message="This will run a full scan of your local workspace. Useful if file changes were not detected correctly."
      setOpen={setRescanDialogOpen}
      confirmButtonLabel="Rescan"
      loadingMessage="Rescanning the workspace..."
    />
  )
}
