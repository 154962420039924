import { ApiState, useApi } from '../useApi'
import { RepositoryWorkspaceManipulationService } from '../../api/coreapi'
import { useIsFocused } from '../useIsFocused'

const UPLOAD_STATUS_REFRESH_MS = 5000
export function useUploadingAgents(
  repoId?: string,
  workspaceId?: string
): ApiState<{
  uploaders?: string[]
}> {
  const isFocused = useIsFocused()
  return useApi(
    ['repos', repoId, 'workspaces', workspaceId, 'uploading_agents'],
    () => {
      if (!repoId || !workspaceId) {
        return {}
      }
      return RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceGetUploadingAgents({
        repoId,
        workspaceId,
      })
    },
    false,
    { refreshInterval: isFocused ? UPLOAD_STATUS_REFRESH_MS : 0 }
  )
}
