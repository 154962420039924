import * as React from 'react'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { ChangedItem, getChangesByPath, isImplicit } from '../../utils/comparisonItemUtils'
import reduce from 'lodash/reduce'
import { getFileName, getParentPath, getPathSeparatorFromPath } from '../../utils/pathUtils'
import styled from '@emotion/styled'
import { BannerPadding, BoxPadding } from '../base/PaddingStyle'
import { TextSmall } from '../base/TextStyle'
import isNil from 'lodash/isNil'
import { Loader } from '../base/Loader'
import isEmpty from 'lodash/isEmpty'
import { remToPx, TREE_LEFT_MARGIN_REM, TREE_LEFT_MARGIN_REM_PADDED } from '../../theme'
import { useNavigate } from 'react-router'
import { SearchBox } from '../selector/SearchBox'
import { FileViewerPathContext } from '../file/useFileAndBaseFile'
import { notNil, toggleFromArray } from '../../utils/objectUtil'
import { Checkbox } from '../base/Checkbox'
import { FileEntry } from '../../api/coreapi'
import { FlexColumn, FlexRow, FlexRowStyle } from '../base/Flex'
import { getEffectiveChangeType } from '../../models/ChangeType'
import { VariableSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TextTruncateAtIndex } from '../base/TextTruncateAtIndex'
import { NodeTitle } from './NodeTitle'

type Props = {
  changedItems: FileEntry[] | undefined
  changesLoading: boolean
  noContentLabel?: string
  checkedKeys?: string[]
  onChecked?: (checkedKeys: string[]) => void
  setCheckedPathsCount?: (checkedFilesCount: number) => void
  selectedNodeKey?: string
  redirectRouteOnClick: (nodeKey: string) => string
  enableWorkspaceActions: boolean
}

const Container = styled(FlexColumn)`
  gap: 1rem;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  padding-bottom: 1rem;
  min-height: 0;
`

const ParentPath = styled.div`
  ${TextSmall};
  ${BoxPadding};
  padding-left: ${TREE_LEFT_MARGIN_REM_PADDED}rem;
  color: ${({ theme }) => theme.colors.black.primary};
  background-color: ${({ theme }) => theme.colors.blue.hover};
  ${FlexRowStyle};
  margin-top: 1rem;
`

const Nodes = styled(FlexColumn)`
  gap: ${({ theme }) => theme.padding.s}rem;
  padding-left: ${TREE_LEFT_MARGIN_REM}rem;
`

const NodeRow = styled(FlexRow)`
  gap: 0.5rem;
  padding-left: ${TREE_LEFT_MARGIN_REM_PADDED}rem;
`

const StyledNode = styled(NodeTitle)`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`

const StyledSearchBox = styled(SearchBox)`
  margin: ${({ theme }) => theme.padding.s}rem ${({ theme }) => theme.padding.s} 0 ${TREE_LEFT_MARGIN_REM_PADDED}rem;
`

const NoContentMessage = styled.div`
  ${BannerPadding};
  color: ${({ theme }) => theme.colors.black.secondary};
  text-align: center;
`

const ITEM_ROW_HEIGHT = 28
const PARENT_FOLDER_LINE_HEIGHT = 39

export const FlatDirsView = ({
  changedItems,
  changesLoading,
  noContentLabel,
  checkedKeys,
  onChecked,
  setCheckedPathsCount,
  selectedNodeKey,
  redirectRouteOnClick,
  enableWorkspaceActions,
}: Props) => {
  const navigate = useNavigate()
  const ref = useRef<VariableSizeList>(null)
  const { setBasePath } = useContext(FileViewerPathContext)
  const [query, setQuery] = useState<string>()
  const changesByPath = useMemo(() => getChangesByPath(changedItems), [changedItems])
  const aggregatedChanges: Record<string, ChangedItem[]> = useMemo(() => {
    if (!changesByPath) {
      return {}
    }
    ref.current?.resetAfterIndex(0, false)
    return reduce(
      changesByPath,
      (result, change, path) => {
        if (isImplicit(change)) {
          return result
        }
        if (query && !path.includes(query)) {
          return result
        }
        if (path === selectedNodeKey) {
          setTimeout(() => setBasePath(change.previousPath))
        }
        const parentPath: string = getParentPath(path)
        ;(result[parentPath] || (result[parentPath] = [])).push(change)
        return result
      },
      {} as Record<string, ChangedItem[]>
    )
  }, [changesByPath, query, selectedNodeKey, setBasePath])
  const onClick = useCallback(
    (change: ChangedItem) => {
      if (!change.isDirectory) {
        setBasePath(change.previousPath)
        navigate(redirectRouteOnClick(change.path))
      }
    },
    [navigate, redirectRouteOnClick, setBasePath]
  )
  const checkable = notNil(onChecked)

  const elements = useMemo(() => {
    const elements: { element: React.JSX.Element; height: number }[] = []
    Object.keys(aggregatedChanges).forEach((parentPath) => {
      if (!isEmpty(parentPath)) {
        elements.push({
          element: (
            <ParentPath key={parentPath}>
              <TextTruncateAtIndex
                text={parentPath + '/'}
                truncateIndex={parentPath.lastIndexOf(getPathSeparatorFromPath(parentPath))}
              />
            </ParentPath>
          ),
          height: PARENT_FOLDER_LINE_HEIGHT,
        })
      }
      aggregatedChanges[parentPath]!.forEach((change) => {
        elements.push({
          element: (
            <NodeRow key={change.path}>
              {checkable && (
                <Checkbox
                  title="Select all"
                  checked={checkedKeys!.includes(change.path) || checkedKeys!.includes(getParentPath(change.path))}
                  setChecked={() => {
                    const nextCheckedKeys = toggleFromArray(checkedKeys!, change.path)
                    onChecked!(nextCheckedKeys)
                    setCheckedPathsCount!(nextCheckedKeys.length)
                  }}
                />
              )}
              <StyledNode
                key={change.path}
                title={getFileName(change.path)}
                path={change.path}
                prevPath={change.previousPath}
                changeType={getEffectiveChangeType(
                  changesByPath?.[parentPath]?.changeType || change.changeType,
                  change.changeType,
                  !isEmpty(change.previousPath) && change.previousPath !== change.path
                )}
                isDirectory={change.isDirectory}
                isLeaf
                isSelected={selectedNodeKey === change.path}
                disableCheckbox={false}
                treeCheckable={false}
                checked={false}
                changedOnly
                isSearchResult={false}
                onClick={() => onClick(change)}
                enableWorkspaceActions={enableWorkspaceActions}
              />
            </NodeRow>
          ),
          height: ITEM_ROW_HEIGHT,
        })
      })
    })
    return elements
  }, [
    aggregatedChanges,
    changesByPath,
    checkable,
    checkedKeys,
    enableWorkspaceActions,
    onChecked,
    onClick,
    selectedNodeKey,
    setCheckedPathsCount,
  ])

  return isNil(changedItems) || changesLoading ? (
    <Loader addPadding />
  ) : (
    <Container>
      {isEmpty(aggregatedChanges) && isEmpty(query) ? (
        <NoContentMessage>{noContentLabel || 'No changes'}</NoContentMessage>
      ) : (
        <>
          <StyledSearchBox hint={'Search items by name'} noPersistSearch onChange={setQuery} />
          {isEmpty(elements) ? (
            <NoContentMessage>No items found</NoContentMessage>
          ) : (
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  itemSize={(i) => elements[i]?.height || ITEM_ROW_HEIGHT}
                  estimatedItemSize={ITEM_ROW_HEIGHT}
                  overscanCount={50}
                  height={height - remToPx(4)}
                  itemCount={elements.length}
                  width={width}
                  ref={ref}
                >
                  {({ index, style }) => {
                    return <Nodes style={style}>{elements[index]?.element}</Nodes>
                  }}
                </VariableSizeList>
              )}
            </AutoSizer>
          )}
        </>
      )}
    </Container>
  )
}
