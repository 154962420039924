import styled from '@emotion/styled'
import SettingsIcon from '@mui/icons-material/Settings'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import React, { useEffect, useMemo, useState } from 'react'
import GroupsIcon from '@mui/icons-material/Groups'
import { RefreshOutlined, CloudOff } from '@mui/icons-material'
import { FlexRow } from '../base/Flex'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import SyncIcon from '@mui/icons-material/Sync'
import { routeToWorkspaceSettings } from '../../Routes'
import { useNavigate } from 'react-router'
import { RepoCollaboratorsDialog } from '../dialogs/RepoCollaboratorsDialog'
import { RescanDialog } from '../dialogs/RescanDialog'
import { IsDesktopApp } from '../../desktop/components/utils/DesktopAppApi'
import { ExpandIcon } from '../base/ExpandIcon'
import { BoxPadding } from '../base/PaddingStyle'
import { PauseSyncDialog } from '../../desktop/components/dialogs/PauseSyncDialog'

type Props = {
  repoId: string
  repoName: string
  workspaceId: string
  isWorkspaceSyncActive: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;
  align-content: center;
  cursor: pointer;
  gap: 4px;
  ${BoxPadding};
`

const DropdownOption = styled(FlexRow)`
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
`

export const ToWorkspaceSettings = ({ repoId, repoName, workspaceId, isWorkspaceSyncActive }: Props) => {
  const navigate = useNavigate()
  const postAnalytics = useAnalytics()
  const isDesktopApp = useMemo(() => IsDesktopApp(), [])
  const [collaboratorsDialogOpen, setCollaboratorsDialogOpen] = useState(false)
  const [isRescanDialogOpen, setIsRescanDialogOpen] = useState(false)
  const [isWorkspaceSettingsOpen, setIsWorkspaceSettingsOpen] = useState(false)
  const [isPauseSyncDialogOpen, setIsPauseSyncDialogOpen] = useState(false)

  useEffect(() => {
    if (collaboratorsDialogOpen || isRescanDialogOpen || isPauseSyncDialogOpen) {
      setIsWorkspaceSettingsOpen(false)
    }
  }, [collaboratorsDialogOpen, isRescanDialogOpen, isPauseSyncDialogOpen])

  const moreOptionsItems = [
    {
      key: 'collaborators',
      title: (
        <DropdownOption>
          <GroupsIcon /> Repo collaborators
        </DropdownOption>
      ),
      onSelected: () => {
        postAnalytics('RepoCollaboratorsDialogOpened', {})
        setCollaboratorsDialogOpen(true)
      },
    },
    {
      key: 'selective-sync',
      title: (
        <DropdownOption>
          <SyncIcon /> Selective sync
        </DropdownOption>
      ),
      onSelected: () => {
        navigate(routeToWorkspaceSettings(repoId, workspaceId))
      },
    },
    ...(isDesktopApp && isWorkspaceSyncActive
      ? [
          {
            key: 'pause-sync',
            title: (
              <DropdownOption>
                <CloudOff /> Pause sync
              </DropdownOption>
            ),
            onSelected: () => {
              postAnalytics('PauseSyncClicked', {})
              setIsPauseSyncDialogOpen(true)
            },
          },
        ]
      : []),
    ...(isDesktopApp && isWorkspaceSyncActive
      ? [
          {
            key: 'rescan',
            title: (
              <DropdownOption>
                <RefreshOutlined /> Rescan
              </DropdownOption>
            ),
            onSelected: () => {
              postAnalytics('RescanWorkspaceClicked', {})
              setIsRescanDialogOpen(true)
            },
          },
        ]
      : []),
  ]

  return (
    <>
      {collaboratorsDialogOpen && (
        <RepoCollaboratorsDialog
          isOpen={collaboratorsDialogOpen}
          setOpen={setCollaboratorsDialogOpen}
          repoId={repoId}
          repoName={repoName}
        />
      )}
      {isRescanDialogOpen && (
        <RescanDialog
          workspaceId={workspaceId}
          isWorkspaceSyncActive={isWorkspaceSyncActive}
          rescanDialogOpen={isRescanDialogOpen}
          setRescanDialogOpen={setIsRescanDialogOpen}
        />
      )}
      {isPauseSyncDialogOpen && (
        <PauseSyncDialog
          workspaceId={workspaceId}
          isPauseSyncDialogOpen={isPauseSyncDialogOpen}
          setIsPauseSyncDialogOpen={setIsPauseSyncDialogOpen}
        />
      )}
      <OptionsDropdown
        items={moreOptionsItems}
        onOpenChanged={(open) => setIsWorkspaceSettingsOpen(open)}
        button={
          <Container>
            <SettingsIcon />
            <ExpandIcon expanded={isWorkspaceSettingsOpen} />
          </Container>
        }
      />
    </>
  )
}
