import { ActionDialog } from './ActionDialog'
import React, { useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { Tier } from '../../api/coreapi'
import { sleep } from '../../utils/sleep'
import { UserTierChooser } from '../user/UserTierChooser'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onConfirm: (tier: Tier) => Promise<void>
}

const isPrimaryButtonDisabled = (selectedTier: string, checkboxChecked: boolean) => {
  return (selectedTier === Tier.INDIE || selectedTier === Tier.EDUCATION) && !checkboxChecked
}

export const SetUserTierDialog = ({ isOpen, setOpen, onConfirm }: Props) => {
  const postAnalytics = useAnalytics()
  const [selectedTier, setSelectedTier] = useState(Tier.PROFESSIONAL)
  const [checkboxChecked, setCheckboxChecked] = useState(false)
  const [extraTierInfo, setExtraTierInfo] = useState('')

  return (
    <ActionDialog
      title="Choose your organization type"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        postAnalytics('SetUserTierClicked', { tier: selectedTier, extra_tier_info: extraTierInfo })
        await Promise.all([onConfirm(selectedTier), sleep(2500)])
      }}
      message={
        <UserTierChooser
          selectedTier={selectedTier}
          setSelectedTier={setSelectedTier}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          setExtraInfo={setExtraTierInfo}
        />
      }
      setOpen={setOpen}
      confirmButtonLabel="Choose tier"
      loadingMessage="Choosing tier..."
      disabled={isPrimaryButtonDisabled(selectedTier, checkboxChecked)}
    />
  )
}
