import React, { useCallback, useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { StyledDialogActions, StyledDialogContent } from '../../components/dialogs/DialogStyle'
import { Loader } from '../../components/base/Loader'
import { FilePath, TextTitle } from '../../components/base/TextStyle'
import { DirStructureTreeView } from '../../components/tree/DirStructureTreeView'
import { PrimaryButton, SecondaryButton } from '../../components/base/PrimaryButton'
import isEmpty from 'lodash/isEmpty'
import styled from '@emotion/styled'
import { useLocalDirTreeData } from '../../hooks/api/useLocalDirTreeData'
import { Popover } from 'antd'
import { remToPx } from '../../theme'

const Title = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const PopoverText = styled.div`
  color: ${({ theme }) => theme.colors.blue.contrastText};
`
interface LocalDirSelectionDialogProps {
  title: string
  subTitle?: string | React.JSX.Element
  treeId: string
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  onClose?: () => void
  buttonLabel: string
  onSelected: (path: string) => void
  loading: boolean
  disabledReason?: string | React.JSX.Element
  onSelectedPath?: (path: string) => void
}
export const LocalDirSelectionDialog = ({
  isOpen,
  setOpen,
  title,
  subTitle,
  treeId,
  onClose,
  buttonLabel,
  onSelected,
  loading,
  disabledReason,
  onSelectedPath,
}: LocalDirSelectionDialogProps) => {
  const [selectedPath, setSelectedPath] = useState<string>('')
  const {
    treeData,
    loading: treeLoading,
    onExpandNodeAsync,
    isNewTree,
    selectedPath: defaultSelectedPath,
  } = useLocalDirTreeData()

  useEffect(() => {
    if (defaultSelectedPath) {
      setSelectedPath(defaultSelectedPath)
      onSelectedPath?.(defaultSelectedPath)
    }
  }, [defaultSelectedPath, onSelectedPath])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    }
    setSelectedPath('')
    setOpen(false)
  }, [setOpen, onClose])

  const onSelectPathCallback = useCallback(
    (path: string) => {
      setSelectedPath(path)
      if (onSelectedPath) {
        onSelectedPath(path)
      }
    },
    [onSelectedPath]
  )

  const isLoading = loading || treeLoading

  const popoverDisabledContent = isEmpty(selectedPath) ? 'Please choose a path.' : disabledReason
  return (
    <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent centerText={isLoading} maxHeight="48rem" width="48rem">
        <Title>{title}</Title>
        {subTitle}
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!isEmpty(selectedPath) && (
              <div>
                Selected path:&ensp;<FilePath>{selectedPath}</FilePath>
              </div>
            )}
            <DirStructureTreeView
              treeId={treeId}
              treeData={treeData!}
              setCheckedPathsCount={() => {}}
              redirectRouteOnClick={() => ''}
              changedOnly={false}
              onExpandNodeAsync={onExpandNodeAsync}
              onSelected={onSelectPathCallback}
              selectedNodeKey={selectedPath}
              onSearch={() => {}}
              searchLoading={false}
              enableWorkspaceActions={false}
              hideSearch={true}
              isNewTree={isNewTree}
              minHeight={remToPx(18)}
            />
          </>
        )}
      </StyledDialogContent>
      {!loading && (
        <StyledDialogActions>
          <Popover
            zIndex={9999}
            content={popoverDisabledContent && <PopoverText>{popoverDisabledContent}</PopoverText>}
            placement="bottom"
          >
            <PrimaryButton
              disabled={isEmpty(selectedPath) || !!popoverDisabledContent}
              onClick={() => onSelected(selectedPath!)}
            >
              {buttonLabel}
            </PrimaryButton>
          </Popover>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
