import { useAgentApi } from '../../hooks/useAgentApi'
import { DefaultService, ApiError } from '../../api/agentapi'
import { sortBy } from 'lodash'
import { milliseconds } from 'date-fns'
import { IsDesktopApp } from '../components/utils/DesktopAppApi'
import { useCallback } from 'react'
import { errorToast, infoToast } from '../../utils/toast'
import { log } from '../../utils/log'

const LOCAL_WORKSPACE_FETCH_INTERVAL: Duration = { seconds: 2 }

export const useLocalWorkspaces = () => {
  const workspaces = useAgentApi(
    'fetch_local_workspace',
    IsDesktopApp()
      ? async () => {
          try {
            const allWorkspaces = await DefaultService.getAllWorkspaces()
            return { data: sortBy(Object.values(allWorkspaces), 'RepoName', 'Path') }
          } catch (error: any) {
            return { error }
          }
        }
      : null,
    true,
    { refreshInterval: milliseconds(LOCAL_WORKSPACE_FETCH_INTERVAL) }
  )

  const unregisterWorkspace = useCallback(async (repoId: string, workspaceId: string) => {
    try {
      await DefaultService.removeClonedWorkspace({ repoId, workspaceId })
      infoToast('Unregistered local workspace successfully')
    } catch (e) {
      errorToast()
      log.error('Unexpected error in unregister local workspace', e)
    }
  }, [])

  const rescanWorkspace = useCallback(async (workspaceId: string, isWorkspaceSyncActive: boolean) => {
    if (!isWorkspaceSyncActive) {
      errorToast('Cannot rescan a paused workspace')
      return
    }

    try {
      await DefaultService.notifyScanRequired({ workspaceId })
      infoToast('Rescan workspace triggered successfully')
    } catch (e) {
      if (e instanceof ApiError && e.status === 404) {
        errorToast('Unable to send rescan call to the workspace, it might have been deleted or paused.')
      } else if (e instanceof ApiError && e.status === 503) {
        errorToast('Cannot rescan. ' + e.message)
      } else {
        errorToast()
        log.error('Unexpected error in rescan workspace', e)
      }
    }
  }, [])

  const pauseWorkspaceSync = useCallback(async (workspaceId: string) => {
    try {
      await DefaultService.pauseWorkspaceSync({ workspaceId })
      infoToast('Local sync paused successfully')
    } catch (e) {
      if (e instanceof ApiError && e.status === 409) {
        errorToast('Unable to pause local sync, the workspace is already paused.')
      } else if (e instanceof ApiError && e.status === 404) {
        errorToast('Unable to pause local sync, the workspace might have been deleted.')
      } else {
        errorToast()
        log.error('Unexpected error in pausing local sync', e)
      }
    }
  }, [])

  return {
    ...workspaces,
    unregisterWorkspace,
    rescanWorkspace,
    pauseWorkspaceSync,
  }
}
