import styled from '@emotion/styled'

export const StyledTextarea = styled.textarea`
  margin: 1rem;
  padding: ${({ theme }) => theme.padding.m}rem;
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.primary};
  outline: 0 none;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  resize: none;
  width: calc(100% - 2rem);
  min-height: 6rem;
  flex-grow: 1;
`
