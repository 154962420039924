import React from 'react'
import styled from '@emotion/styled'
import { routeToProfile } from '../../Routes'
import { FlexRowStyle } from '../base/Flex'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { TabContext } from '@mui/lab'
import { Outlet } from 'react-router'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.primary};
  ${FlexRowStyle};
  height: 100%;
  width: 100%;
  justify-content: start;
`

const StyledTabs = styled(Tabs)`
  border-right: 1px solid ${({ theme }) => theme.colors.white.secondary};
  height: 100%;
  min-width: 120px;
  align-self: flex-start;
  padding: 16px 0;
`

interface LinkTabProps {
  label?: string
  href?: string
  selected?: boolean
}

const samePageLinkNavigation = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
  !(
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  )

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // Routing libraries handle this, you can remove the onClick handle when using them.
        if (samePageLinkNavigation(event)) {
          event.preventDefault()
        }
      }}
      aria-current={props.selected && 'page'}
      {...props}
    />
  )
}

export const SettingsRoute = () => {
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' && samePageLinkNavigation(event as React.MouseEvent<HTMLAnchorElement, MouseEvent>))
    ) {
      setValue(newValue)
    }
  }

  return (
    <Container>
      <TabContext value="profile">
        <StyledTabs
          orientation="vertical"
          variant="fullWidth"
          aria-label="User settings tabs"
          value={value}
          onChange={handleChange}
          role="navigation"
        >
          <LinkTab label="Profile" href={routeToProfile()} selected />
        </StyledTabs>
        <Outlet />
      </TabContext>
    </Container>
  )
}
