import { ApiError } from '../api/coreapi'
import { StatusCodes } from 'http-status-codes'
import { USER_INFO_API } from '../api/auth'
import { log } from './log'

export enum UserFailureType {
  ResourceNotFound,
  NoPermissions,
  ReloadRequired,
  UserDisabled,
  NameConflict,
  Network,
}

type IsUserSideFailure = {
  isUserSideFailure: boolean
  userFailureType?: UserFailureType
}

const NOT_FOUND_USER_FAILURES_DETAIL = [
  'Branch not found',
  'Tag not found',
  'No such repo',
  'No such ref',
  'No repo was found',
  'No workspace was found',
  'Ref not found',
  "Couldn't find repo",
  'was not found',
  'No open merge was found',
]
const FORBIDDEN_USER_FAILURES_DETAIL = ['Access Denied']
const USER_NOT_FOUND_DETAIL = ['Unknown user']
const TOKEN_EXPIRED_DETAIL = ['Signature has expired']
const NAME_CONFLICT_DETAIL = ['already exists for this']

const apiErrorDetailIncludes = (error: ApiError, options: string[]) => {
  const detail = (error.body?.detail || '').toLowerCase()
  return options.some((option) => detail.includes(option.toLowerCase()))
}

const authenticationErrorCodes = [StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN]

export const getIsUserSideFailure = (error: any): IsUserSideFailure => {
  if (isNetworkError(error)) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.Network }
  }
  if (error.apiError) {
    return getIsUserSideFailure(error.apiError)
  }
  if (error?.name === 'ChunkLoadError' || error?.toString().includes('postMessage is not a function')) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.ReloadRequired }
  }
  if (!(error instanceof ApiError)) {
    return { isUserSideFailure: false }
  }
  if (error.status === StatusCodes.NOT_FOUND && apiErrorDetailIncludes(error, NOT_FOUND_USER_FAILURES_DETAIL)) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.ResourceNotFound }
  }
  if (
    authenticationErrorCodes.includes(error.status) &&
    apiErrorDetailIncludes(error, FORBIDDEN_USER_FAILURES_DETAIL)
  ) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.NoPermissions }
  }
  if (authenticationErrorCodes.includes(error.status) && apiErrorDetailIncludes(error, USER_NOT_FOUND_DETAIL)) {
    log.error('User authenticated but is not found', { error })
    return { isUserSideFailure: true, userFailureType: UserFailureType.NoPermissions }
  }
  if (error.status === StatusCodes.CONFLICT && apiErrorDetailIncludes(error, NAME_CONFLICT_DETAIL)) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.NameConflict }
  }
  if (error.status === StatusCodes.PRECONDITION_REQUIRED) {
    return { isUserSideFailure: true, userFailureType: UserFailureType.UserDisabled }
  }
  return { isUserSideFailure: false }
}

export const userFailureMessage = (type: UserFailureType) => {
  const messages: Record<UserFailureType, string> = {
    [UserFailureType.ResourceNotFound]: 'Resource not found',
    [UserFailureType.NoPermissions]: 'No permissions to view this resource',
    [UserFailureType.ReloadRequired]: 'Browser cache error',
    [UserFailureType.UserDisabled]: 'User not yet enabled',
    [UserFailureType.NameConflict]: 'Name conflict',
    [UserFailureType.Network]: 'Network error',
  }
  return messages[type]
}

export const isTokenExpiredError = (error: any) =>
  error instanceof ApiError &&
  ((authenticationErrorCodes.includes(error.status) && apiErrorDetailIncludes(error, TOKEN_EXPIRED_DETAIL)) ||
    (error.status === StatusCodes.UNAUTHORIZED && error.request.url.endsWith(USER_INFO_API)))

export const isTokenOutdatedError = (error: any) =>
  error instanceof ApiError &&
  error.status === StatusCodes.FORBIDDEN &&
  apiErrorDetailIncludes(error, ["Provided token doesn't have the required scope"])

export const isNetworkError = (error: any) =>
  error.message?.includes('Failed to fetch') ||
  error.message?.includes('Network Error') ||
  error.message?.includes('Load failed') ||
  error.message?.includes('timeout of 0ms exceeded') ||
  error.message?.includes('Request aborted') ||
  (error.message?.includes('ECONNABORTED') && error.message?.includes('timeout of'))

export const isFileNotFoundError = (error: any) =>
  error instanceof ApiError &&
  ((error.status === StatusCodes.NOT_FOUND &&
    apiErrorDetailIncludes(error, ['was not found', 'was deleted', 'ensure workspace integrity', 'no such path'])) ||
    (error.status === StatusCodes.METHOD_NOT_ALLOWED &&
      apiErrorDetailIncludes(error, ['This endpoint allows to transfer only regular files'])))

export const isPreconditionFailedError = (error: any) =>
  error instanceof ApiError && error.status === StatusCodes.PRECONDITION_FAILED

export const isMergeObsoleteError = (error: any) =>
  error instanceof ApiError &&
  error.status === StatusCodes.METHOD_NOT_ALLOWED &&
  apiErrorDetailIncludes(error, ['merge is obsolete'])

export const isBlobRedirectError = (error: any) => error instanceof ApiError && error.status === StatusCodes.NO_CONTENT

export const isCommitFailedWithConflictsError = (error: any) =>
  error instanceof ApiError && error.status === StatusCodes.CONFLICT && apiErrorDetailIncludes(error, ['has conflicts'])

export const isCheckoutFailedWithPendingChanges = (error: any) =>
  error instanceof ApiError && error.status === StatusCodes.CONFLICT && apiErrorDetailIncludes(error, ['has changes'])

export const isOperationFailedWithNewerChanges = (error: any) =>
  error instanceof ApiError &&
  error.status === StatusCodes.PRECONDITION_FAILED &&
  apiErrorDetailIncludes(error, ['new changes'])

export const isInviteAlreadySentError = (error: any) =>
  error instanceof ApiError &&
  error.status === StatusCodes.BAD_REQUEST &&
  apiErrorDetailIncludes(error, ['already has a pending invitation'])

export const isNotSupportedWithGitSyncError = (error: any) =>
  error instanceof ApiError &&
  error.status === StatusCodes.NOT_IMPLEMENTED &&
  apiErrorDetailIncludes(error, ['repositories synced with git'])
