import { useCallback, useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { PrimaryButton, SecondaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { Loader } from '../base/Loader'
import { callAsync } from '../../utils/callAsync'
import { TextBold, TextRegular, TextTitle } from '../base/TextStyle'
import { PublishApiErrorContext } from '../../App'
import { useMountedState } from '../../hooks/useMountedState'
import isEmpty from 'lodash/isEmpty'
import {
  Branch,
  Commit,
  NewResourceId,
  RepositoryBranchManipulationService,
  RepositoryShelvesManipulationService,
  Shelf,
} from '../../api/coreapi'
import { useRepo } from '../../hooks/api/useRepo'
import { StyledDialogActions, StyledDialogContent } from './DialogStyle'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useBranch } from '../../hooks/api/useBranch'
import { CodeRef } from '../base/CodeRef'
import CallSplitIcon from '@mui/icons-material/CallSplit'
import { useCommit } from '../../hooks/api/useCommit'
import { useKeyPress } from '../../hooks/useKeyPress'
import * as KeyCode from 'keycode-js'
import { truncateCommitMessage } from '../../utils/textUtils'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'
import { Checkbox } from '../base/Checkbox'
import { FlexRow } from '../base/Flex'
import { useCheckoutAsync } from '../branch/useCheckout'

const Message = styled.span`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
`

const Title = styled(Message)`
  ${TextBold};
`

const InfoText = styled.div`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.secondary};
`

const ErrorText = styled.div`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.red.primary};
`

const StyledNewBranchIcon = styled(CallSplitIcon)`
  ${TextBold};
  margin-bottom: 4px;
  margin-right: ${({ theme }) => theme.padding.s}rem;
`

const StyledInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.padding.s}rem;
`

const StyledInput = styled.input<{ error?: boolean }>`
  background-color: inherit;
  border: ${({ theme, error }) => `${error ? theme.colors.red.primary : theme.colors.stroke} 1px solid`};
  padding: ${({ theme }) => theme.padding.m}rem;
  outline: 0 none;
  width: 100%;
  border-radius: 1rem;
`

const isCommit = (commitOrBranch?: Branch | Commit): commitOrBranch is Commit =>
  !!commitOrBranch && 'parents' in commitOrBranch

// Branch name validation rules taken from core/src/handlersv2/branch.py
const isValidBranchName = (branchName: string): boolean => {
  const invalidPatterns = [
    /[~^:?*[\]@{}\\ ]/, // Invalid characters (removed unnecessary escapes)
    /\.\./, // Double dot
    /\/\//, // Double slash
    /@{/, // At sign followed by curly brace
    /\.lock$/, // Ends with .lock
    /\/$/, // Ends with slash
    /^\./, // Starts with dot
  ]
  return !invalidPatterns.some((pattern) => pattern.test(branchName))
}

type Props = {
  repoId: string
  sourceBranchOrCommit?: Branch | Commit
  isOpen: boolean
  setOpen: (open: boolean) => void
  onCreated: (branchId: string, commitId: string, workspaceId?: string) => void
  onHasPendingChanges: (branchId: string, workspaceId: string) => void
  onHasShelvedChanges: (shelves: Shelf[], branchId: string, workspaceId: string) => void
}

export const NewBranchDialog = ({
  repoId,
  sourceBranchOrCommit,
  isOpen,
  setOpen,
  onCreated,
  onHasPendingChanges,
  onHasShelvedChanges,
}: Props) => {
  const [saveLoading, setLoading] = useMountedState(false)
  const [branchName, setBranchName] = useState<string>('')
  const [checkoutWorkspace, setCheckoutWorkspace] = useState(true)
  const { workspace } = useCachedWorkspace(repoId, undefined)
  const checkoutAsync = useCheckoutAsync(repoId, workspace?.workspace_id, onHasPendingChanges)
  const onApiError = useContext(PublishApiErrorContext)
  const [branchNameError, setBranchNameError] = useState<string>('')
  const handleClose = useCallback(() => {
    setLoading(false)
    setBranchName('')
    setBranchNameError('')
    setOpen(false)
  }, [setOpen, setLoading, setBranchName, setBranchNameError])
  const { repo, repoLoading } = useRepo(repoId)
  const { branch: defaultBranch, branchLoading: defaultBranchLoading } = useBranch(repoId, repo?.default_branch_id)
  const {
    branch: sourceBranch,
    branchLoading: sourceBranchLoading,
    refresh: refreshSourceBranch,
  } = useBranch(repoId, sourceBranchOrCommit?.branch_id)
  // Add the source branch before sourceBranchOrCommit in order to get the updated branch from the hook if we are in the branch case
  const selectedBranchOrCommit = isCommit(sourceBranchOrCommit) ? sourceBranchOrCommit : sourceBranch || defaultBranch
  const fromBranchName = sourceBranch?.branch_name || defaultBranch?.branch_name
  const { commit, loading: commitLoading } = useCommit(repoId, selectedBranchOrCommit?.commit_id)
  const postAnalytics = useAnalytics()

  // Force refresh the source branch when the dialog is opened
  useEffect(() => {
    if (isOpen) {
      refreshSourceBranch()
    }
  }, [isOpen, refreshSourceBranch])

  const handleBranchNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value || ''
    setBranchName(newName)
    if (newName && !isValidBranchName(newName)) {
      setBranchNameError('Branch name contains invalid characters or format')
    } else {
      setBranchNameError('')
    }
  }, [])

  const handleConfirm = useCallback(async () => {
    await callAsync(
      async () => {
        const branchId = (await RepositoryBranchManipulationService.srcHandlersv2BranchPost({
          repoId,
          requestBody: {
            commit_id: selectedBranchOrCommit!.commit_id,
            branch_name: branchName,
          },
        })) as NewResourceId
        if (checkoutWorkspace && workspace) {
          const hasPendingChanges = await checkoutAsync(branchId.id)
          const shelves = (
            (await RepositoryShelvesManipulationService.srcHandlersv2ShelfListAll({
              repoId: repoId!,
              branchId: branchId.id,
            })) as { items: Shelf[] }
          ).items
          if (!isEmpty(shelves)) {
            onHasShelvedChanges(shelves, branchId.id, workspace.workspace_id)
          } else if (!hasPendingChanges) {
            onCreated(branchId.id, selectedBranchOrCommit!.commit_id, workspace.workspace_id)
          }
        } else {
          onCreated(branchId.id, selectedBranchOrCommit!.commit_id)
        }
      },
      setLoading,
      onApiError,
      handleClose
    )
  }, [
    setLoading,
    onApiError,
    handleClose,
    repoId,
    selectedBranchOrCommit,
    branchName,
    onCreated,
    checkoutWorkspace,
    workspace,
    checkoutAsync,
    onHasShelvedChanges,
  ])
  const loading = saveLoading || repoLoading || defaultBranchLoading || sourceBranchLoading || commitLoading

  useEffect(() => {
    if (isOpen) {
      postAnalytics('NewBranchDialogOpened', { repo_id: repoId })
    }
  }, [isOpen, postAnalytics, repoId])

  useEffect(() => {
    if (repo && isEmpty(repo.default_branch_id)) {
      setOpen(false)
    }
  }, [repo, setOpen])

  const commitId = selectedBranchOrCommit?.commit_id
  const commitMsg = commit ? truncateCommitMessage(commit) : ''

  const isPrimaryActionDisabled =
    repoLoading || isEmpty(branchName) || isEmpty(selectedBranchOrCommit) || saveLoading || !!branchNameError
  useKeyPress(KeyCode.CODE_ENTER, handleConfirm, isOpen && !isPrimaryActionDisabled)
  return (
    <Dialog onClose={() => handleClose} aria-labelledby="customized-dialog-title" open={isOpen}>
      <StyledDialogContent centerText={loading}>
        <Title>
          <StyledNewBranchIcon />
          Create New Branch
        </Title>
        {loading ? (
          <>
            <Loader />
            <Message>Creating branch...</Message>
          </>
        ) : (
          <>
            <StyledInputWrapper>
              <StyledInput
                autoFocus
                type="text"
                placeholder="Branch name"
                onChange={handleBranchNameChange}
                error={!!branchNameError}
              />
              {branchNameError && <ErrorText>{branchNameError}</ErrorText>}
            </StyledInputWrapper>
            <InfoText>
              The new branch will be branched from:
              <div>
                Branch: <CodeRef>{fromBranchName}</CodeRef>
              </div>{' '}
              <div>
                Commit: <CodeRef>{commitId}</CodeRef> (message: <CodeRef>{commitMsg}</CodeRef>)
              </div>
            </InfoText>
            {!!workspace && (
              <FlexRow gap={0.5} centered={true}>
                <Checkbox title={'Switch to branch'} checked={checkoutWorkspace} setChecked={setCheckoutWorkspace} />
                <InfoText onClick={() => setCheckoutWorkspace((b) => !b)}>
                  Switch to the new branch in the current workspace (current workspace:{' '}
                  <CodeRef>{workspace.name}</CodeRef>)
                </InfoText>
              </FlexRow>
            )}
          </>
        )}
      </StyledDialogContent>
      {!saveLoading && (
        <StyledDialogActions>
          <PrimaryButton disabled={isPrimaryActionDisabled} onClick={handleConfirm}>
            Create
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </StyledDialogActions>
      )}
    </Dialog>
  )
}
