import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tree } from '../../models/Tree'
import { buildTree } from '../../utils/buildTree'
import { FILE_MODE_DIRECTORY } from '../../models/fileMode'
import { FileEntry } from '../../api/coreapi'
import { DefaultService } from '../../api/agentapi'
import { ObjectStatusValues } from '../../models/ChangeType'
import { errorToast } from '../../utils/toast'
import { log } from '../../utils/log'

export const useLocalDirTreeData = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [treeData, setTreeData] = useState<Tree>()
  const [isNewTree, setIsNewTree] = useState<boolean>(true)
  const [selectedPath, setSelectedPath] = useState<string>('')

  const listDirs = useCallback(async (paths: string[]) => {
    try {
      const res = await DefaultService.listDir({ requestBody: { paths: paths } })
      if (paths.length === 0 && res.paths.length > 0) {
        setSelectedPath(res.paths[0] || '')
      }
      return res.paths.map((p: string) => ({
        path: p,
        status: ObjectStatusValues.Intact,
        mode: FILE_MODE_DIRECTORY,
      }))
    } catch (e) {
      const msg = 'Error fetching local directory information'
      errorToast(msg)
      log.error(msg, e)
      return []
    }
  }, [])
  const buildTreeOptions = useMemo(() => ({ skipMergeSingleChildren: true }), [])
  useEffect(() => {
    const initiate = async () => {
      const rootDirs = await listDirs([])
      setTreeData(buildTree(rootDirs, undefined, undefined, new Set([]), buildTreeOptions))
      setLoading(false)
    }
    initiate()
  }, [listDirs, buildTreeOptions])
  const onExpandNodeAsync = async (path: string) => {
    const newPaths: FileEntry[] = await listDirs([path])

    let nextTree: Tree | undefined = undefined
    setTreeData((tree) => {
      if (!tree || !tree.nodeByKey[path]) {
        // setTreeData race condition
        return tree
      }
      const loadedDirPaths = new Set(tree?.loadedDirPaths)
      loadedDirPaths.add(path)
      nextTree = buildTree(
        [...tree!.files, ...newPaths],
        tree?.workspaceJournalOrdinalId,
        undefined,
        loadedDirPaths,
        buildTreeOptions
      )
      return nextTree
    })
    setIsNewTree(false)
    return { nodes: (nextTree as Tree | undefined)?.nodeByKey[path]?.children || [], loadedKeys: [path] }
  }

  return { loading, treeData, onExpandNodeAsync, isNewTree, selectedPath }
}
