import { ActionDialog } from '../../../components/dialogs/ActionDialog'
import React from 'react'
import { useLocalWorkspaces } from '../../hooks/useLocalWorkspaces'

type Props = {
  workspaceId: string
  isPauseSyncDialogOpen: boolean
  setIsPauseSyncDialogOpen: (open: boolean) => void
}

export const PauseSyncDialog = ({ workspaceId, isPauseSyncDialogOpen, setIsPauseSyncDialogOpen }: Props) => {
  const { pauseWorkspaceSync } = useLocalWorkspaces()

  return (
    <ActionDialog
      title="Pause Workspace Sync"
      isOpen={isPauseSyncDialogOpen}
      onConfirmAsync={() => pauseWorkspaceSync(workspaceId)}
      message="This will pause synchronization with your cloud workspace. Local changes won't be uploaded, and remote changes won't be downloaded. You can resume sync at anytime."
      setOpen={setIsPauseSyncDialogOpen}
      confirmButtonLabel="Pause"
      loadingMessage="Pausing the local workspace..."
    />
  )
}
