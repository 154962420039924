import { useState } from 'react'
import { useEventListener } from 'usehooks-ts'

export const useIsFocused = () => {
  const [isFocused, setIsFocused] = useState<boolean>(true)

  useEventListener('focus', () => setIsFocused(true))
  useEventListener('blur', () => setIsFocused(false))

  return isFocused
}
