import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { ActionDialog } from './ActionDialog'
import { RepositoryManipulationService } from '../../api/coreapi'
import { infoToast } from '../../utils/toast'
import { FlexColumn } from '../base/Flex'
import { CodeRef } from '../base/CodeRef'
import { StyledTextarea } from '../base/Input'

type DeleteRepoDialogProps = {
  repoId: string
  repoName: string
  afterDelete: () => void
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

const Bold = styled.div`
  font-weight: 500;
`

const ConfirmTextarea = styled(StyledTextarea)`
  min-height: 2.5rem;
  margin: 0.5rem 0;
  width: 100%;
`

export const DeleteRepoDialog = ({ repoId, repoName, afterDelete, isOpen, setIsOpen }: DeleteRepoDialogProps) => {
  const postAnalytics = useAnalytics()
  const [confirmText, setConfirmText] = useState('')

  useEffect(() => {
    if (isOpen) {
      setConfirmText('')
      postAnalytics('DeleteRepoDialogOpened', { repo_id: repoId })
    }
  }, [isOpen, postAnalytics, repoId])

  const isConfirmValid = confirmText.trim() === repoName

  return (
    <ActionDialog
      title="Delete Repository"
      isOpen={isOpen}
      setOpen={setIsOpen}
      onConfirmAsync={async () => {
        if (!isConfirmValid) return
        await RepositoryManipulationService.srcHandlersv2RepoDelete({
          repoId,
        })
        infoToast(`Repository ${repoName} deleted`, true)
        afterDelete()
      }}
      message={
        <FlexColumn gap={1}>
          <div>
            Are you sure you want to delete the repository <CodeRef>{repoName}</CodeRef>
          </div>
          <Bold>Warning! Once you delete a repository, it's really gone - no respawns.</Bold>
          <div>
            Please type <CodeRef>{repoName}</CodeRef> to confirm deletion:
          </div>
          <ConfirmTextarea
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            placeholder={`Type ${repoName} to confirm`}
            autoFocus
            rows={1}
          />
        </FlexColumn>
      }
      confirmButtonLabel="Delete"
      loadingMessage="Deleting repository..."
      isDanger
      disabled={!isConfirmValid}
    />
  )
}
