import styled from '@emotion/styled'
import * as React from 'react'
import { TREE_LEFT_MARGIN_REM_PADDED } from '../../theme'
import { NodeTitle } from './NodeTitle'

const RootNodeStyle = styled(NodeTitle)`
  margin-left: ${TREE_LEFT_MARGIN_REM_PADDED}rem;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
`

export const RootNode = ({ isSelected, onSelected }: { isSelected: boolean; onSelected?: (key: string) => void }) => {
  return (
    <RootNodeStyle
      key=""
      title="."
      path=""
      isDirectory
      isLeaf
      changeType="Intact"
      disableCheckbox
      isSelected={isSelected}
      treeCheckable={false}
      changedOnly={false}
      isSearchResult={false}
      onClick={() => onSelected?.('')}
      enableWorkspaceActions={false}
    />
  )
}
