/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Error } from '../models/Error';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserManagementService {
    /**
     * Get user information
     * @returns User User information returned
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2UserGetUserInfo(): CancelablePromise<User | Error> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user',
        });
    }
    /**
     * Update user information
     * @returns any Request completed successfully
     * @returns Error An error occurred
     * @throws ApiError
     */
    public static srcHandlersv2UserUpdateUserInfo({
        requestBody,
    }: {
        requestBody: User,
    }): CancelablePromise<Record<string, any> | Error> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
