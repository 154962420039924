import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { Tier } from '../../api/coreapi'
import { FlexColumn } from '../base/Flex'
import { Checkbox } from '../base/Checkbox'

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  cursor: pointer;
`
const InstitutionField = styled(TextField)`
  width: 100%;
  text-align: left;
`

const MessageContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
`

const CheckboxContainer = styled.div`
  padding: 12px;
`

const TopAlignedFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;

  .MuiFormControlLabel-label {
    padding: 0 12px;
  }
`

const UserTierContainer = styled.div`
  color: ${({ theme }) => theme.colors.black.primary};
`

type UserTierChooserProps = {
  selectedTier: Tier
  setSelectedTier: (value: ((prevState: Tier) => Tier) | Tier) => void
  checkboxChecked: boolean
  setCheckboxChecked: (value: ((prevState: boolean) => boolean) | boolean) => void
  setExtraInfo: (value: ((prevState: string) => string) | string) => void
}

export const UserTierChooser = ({
  selectedTier,
  setSelectedTier,
  checkboxChecked,
  setCheckboxChecked,
  setExtraInfo,
}: UserTierChooserProps) => {
  useEffect(() => {
    setCheckboxChecked(false)
  }, [selectedTier, setCheckboxChecked])

  return (
    <UserTierContainer>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="user-tier"
          name="user-tier"
          value={selectedTier}
          onChange={(e) => setSelectedTier(e.target.value as Tier)}
        >
          <FormControlLabel
            label="Professional ($25/user, 21 day free trial, no credit card required)"
            control={<Radio />}
            value={Tier.PROFESSIONAL}
          />
          <FormControlLabel label="Indie (starting at $0/user)" control={<Radio />} value={Tier.INDIE} />
          <FormControlLabel label={'Educational'} control={<Radio />} value={Tier.EDUCATION} />
        </RadioGroup>
      </FormControl>
      {selectedTier === Tier.INDIE && (
        <IndieTierEligibilityCheckbox checked={checkboxChecked} setChecked={setCheckboxChecked} />
      )}
      {selectedTier === Tier.EDUCATION && (
        <FlexColumn gap={1} centered>
          <EducationTierEligibilityCheckbox checked={checkboxChecked} setChecked={setCheckboxChecked} />
          <InstitutionField
            required
            label="Institution and course name"
            variant="outlined"
            size="small"
            onChange={(e) => setExtraInfo(e.target.value)}
          />
        </FlexColumn>
      )}
      <MessageContainer>
        Not sure which tier?{' '}
        <StyledLink href={`https://www.diversion.dev/pricing`} target="_blank" rel="noopener noreferrer">
          Compare tiers
        </StyledLink>
      </MessageContainer>
    </UserTierContainer>
  )
}

const IndieTierEligibilityCheckbox = ({
  checked,
  setChecked,
}: {
  checked: boolean
  setChecked: (checked: boolean) => void
}) => (
  <FlexColumn>
    <CheckboxContainer>
      <TopAlignedFormControlLabel
        control={<Checkbox title="I am qualified for this tier" checked={checked} setChecked={setChecked} />}
        label="I certify my organization qualifies for the Indie tier: under $100K revenue, less than $1M funding, and fewer than 20 people."
      />
    </CheckboxContainer>
  </FlexColumn>
)

const EducationTierEligibilityCheckbox = ({
  checked,
  setChecked,
}: {
  checked: boolean
  setChecked: (checked: boolean) => void
}) => (
  <FlexColumn>
    <CheckboxContainer>
      <TopAlignedFormControlLabel
        control={<Checkbox title=" " checked={checked} setChecked={setChecked} />}
        label="I certify that my use of Diversion is for studies at a recognized academic institution."
      />
    </CheckboxContainer>
  </FlexColumn>
)
